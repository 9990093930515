import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Grid } from '@mui/material';

import syncingIcon from '../assets/syncing.png';
import IntegrationButton from '../components/integrationButton';
import { Colors } from '../config/default';
import MainCards from '../components/listCard';
import { user_information } from '../redux/actions/action';
import { getUserInfo } from '../services/services';
import { formatNumberWithoutDecimal } from '../services/common';

export default function ManualSync() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const erpType = userDetails?.user?.erpType;

  const isConstructionCompany = useSelector(
    (state) => state?.userInfo?.userInfo?.user?.constructionCompany
  );
  const cognitoUsername = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.accessToken?.payload?.username
  );
  const intervalCall = async () => {
    const getUserresult = await getUserInfo(cognitoUsername);
    if (getUserresult?.status === 200) {
      dispatch(user_information(getUserresult?.data?.payload?.data));
    } else if (getUserresult?.response.status === 401) {
      localStorage.clear();
      navigate('/');
    }
  };
  useEffect(() => {
    intervalCall();
  }, []);
  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: { xs: 'center', xl: 'flex-start' },
          height: { xs: '64vh', sm: '69vh' },
          backgroundColor: Colors.BG_BLACK,
          overflowY: { xs: 'scroll', lg: 'visible' },
          '&::-webkit-scrollbar': {
            width: '0em',
            height: '0em',
            borderRadius: '20px'
          }
        }}>
        <Grid container item xs={12}>
          <Grid
            item
            xs={12}
            md={3.4}
            lg={2.4}
            xl={2}
            sx={{
              display: { xs: 'flex', md: 'block' },
              justifyContent: { xs: 'center', sm: 'flex-start' },
              ml: { sm: '1em', md: '2em', lg: '0' },
              mt: { xs: '1em', md: '0' }
            }}>
            <IntegrationButton
              width="13em"
              cardIcon={syncingIcon}
              cardTitle="MANUAL SYNC"
              toggle={false}
              buttonName="ACTIVE"
              activity={Colors.DARKBLUE}
              subTitle="Manually upload your files to Beiing Human"
              cursor={false}
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={7.8}
            lg={9.6}
            xl={10}
            sx={{
              gap: '0.9rem',
              justifyContent: 'flex-start',
              padding: { xs: '1rem', md: 'auto' },
              height: 'max-content',
              maxHeight: '75vh',
              backgroundColor: Colors.BG_BLACK,
              overflowY: 'auto'
            }}>
            <MainCards
              data={formatNumberWithoutDecimal(userDetails?.glInfo?.vendor)}
              title="Vendors"
              type="vendors"
            />
            <MainCards
              data={formatNumberWithoutDecimal(userDetails?.glInfo?.glAccount)}
              title="Gl Accounts"
              type="accounts"
            />
            <MainCards
              data={formatNumberWithoutDecimal(userDetails?.glInfo?.purchaseOrder)}
              title="Purchase Orders"
              type="purchaseOrders"
            />

            {isConstructionCompany ? (
              <>
                <MainCards
                  data={formatNumberWithoutDecimal(userDetails?.glInfo?.costType)}
                  title={erpType !== 'QuickBooksOnline' ? 'Cost Types' : 'Classes'}
                  type="costTypes"
                />
                {erpType !== 'QuickBooksOnline' && (
                  <>
                    {erpType !== 'DeltekComputerEase' && (
                      <MainCards
                        data={formatNumberWithoutDecimal(userDetails?.glInfo?.costIds)}
                        title="Cost Ids"
                        type="costIds"
                      />
                    )}
                    {erpType !== 'QuickBooksDesktop' && (
                      <MainCards
                        data={formatNumberWithoutDecimal(userDetails?.glInfo?.phase)}
                        title="Phases"
                        type="phaseList"
                      />
                    )}
                  </>
                )}

                <MainCards
                  data={formatNumberWithoutDecimal(userDetails?.glInfo?.jobIds)}
                  title={erpType !== 'QuickBooksOnline' ? 'Job Ids' : 'Project Ids'}
                  type="jobIds"
                />
              </>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
