import React from 'react';

import { Grid, Typography } from '@mui/material';
import { Person2Outlined, EmailOutlined, AccountBoxOutlined } from '@mui/icons-material';

import { Colors } from '../config/default';

export default function Approvers({ previousApprover, nextApprover }) {
  return (
    <Grid
      sx={{
        height: '70vh'
      }}>
      <Typography sx={{ textAlign: 'center', color: Colors.WHITE, mt: '1em' }}>
        Approvers
      </Typography>
      <Grid
        sx={{
          height: '95%',
          paddingRight: '1em',
          overflowY: 'auto'
        }}>
        {previousApprover?.length > 0 && (
          <div style={{ color: Colors.WHITE, marginTop: '1em' }}>Previous Approver</div>
        )}
        {previousApprover?.map((prev, index) => (
          <div
            key={index}
            style={{
              height: '116px',
              backgroundColor: Colors.Canvas_Left_BG,
              borderRadius: '10px',
              color: Colors.TEXTGREY,
              marginTop: '1em',
              paddingLeft: '10%',
              paddingTop: '1em',
              lineHeight: '1.8em'
            }}>
            <div>
              <Person2Outlined sx={{ color: Colors.LIGHTBLUE, fontSize: '18px', mr: '8px' }} />
              {prev?.name}
            </div>
            <div>
              <EmailOutlined sx={{ color: Colors.LIGHTBLUE, fontSize: '18px', mr: '8px' }} />
              {prev?.email}
            </div>
            <div>
              <AccountBoxOutlined sx={{ color: Colors.LIGHTBLUE, fontSize: '18px', mr: '8px' }} />
              {prev?.roll}
            </div>
            <div
              style={{
                textAlign: 'right'
              }}>
              <span
                style={{
                  backgroundColor: prev?.status === 'Pending' ? Colors.YELLOW : Colors.LIGHTBLUE,
                  color: Colors.WHITE,
                  padding: '6px 10px',
                  borderTopLeftRadius: '10px',
                  borderBottomRightRadius: '10px'
                }}>
                {prev?.status}
              </span>
            </div>
          </div>
        ))}
        {nextApprover?.length > 0 && (
          <div style={{ color: Colors.WHITE, marginTop: '1em' }}>Next Approver</div>
        )}
        {nextApprover?.map((next, index) => (
          <div
            key={index}
            style={{
              height: '116px',
              backgroundColor: Colors.Canvas_Left_BG,
              borderRadius: '10px',
              color: Colors.TEXTGREY,
              marginTop: '1em',
              paddingLeft: '10%',
              paddingTop: '1em',
              lineHeight: '1.8em'
            }}>
            <div>
              <Person2Outlined sx={{ color: Colors.LIGHTBLUE, fontSize: '18px', mr: '8px' }} />
              {next?.name}
            </div>
            <div>
              <EmailOutlined sx={{ color: Colors.LIGHTBLUE, fontSize: '18px', mr: '8px' }} />
              {next?.email}
            </div>
            <div>
              <AccountBoxOutlined sx={{ color: Colors.LIGHTBLUE, fontSize: '18px', mr: '8px' }} />
              {next?.roll}
            </div>
            <div style={{ textAlign: 'right' }}>
              <span
                style={{
                  backgroundColor: next?.status === 'Pending' ? Colors.YELLOW : Colors.LIGHTBLUE,
                  color: Colors.WHITE,
                  padding: '6px 10px',
                  borderTopLeftRadius: '10px',
                  borderBottomRightRadius: '10px'
                }}>
                {next?.status}
              </span>
            </div>
          </div>
        ))}
      </Grid>
    </Grid>
  );
}
