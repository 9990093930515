import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Gravatar from 'react-gravatar';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { Box, Modal, Button, Grid, Typography, CircularProgress, TextField } from '@mui/material';
import { Close, ChatOutlined, ErrorOutline, Send } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';

import { Colors } from '../config/default';
import { getDocumentDetails } from '../services/services';
import { documentCommentsText } from '../constants/appConstants';

export default function DocumentComments({ isDrawerOpen, id, comment, setComment, open, setOpen }) {
  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);
  const { COMMENTS, EMPTY_COMMENTS } = documentCommentsText;
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const callSource = userRole === 'Vendor' ? 'api' : 'app';
  const [commentArray, setCommentArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputVal, setInputVal] = useState('');
  const handleClose = () => setOpen(false);
  const popupRef = useRef(null);

  const selectedData = async () => {
    setLoading(true);
    const result = await getDocumentDetails(id, callSource);
    if (result?.response?.status === 401) {
      localStorage.clear();
      navigate('/');
    }
    const res = result?.data?.payload?.data?.document?.comment || [];
    setCommentArray((prevComments) => [...res, ...comment]);
    setLoading(false);
  };

  const handleOpen = (event) => {
    event.stopPropagation();
    setOpen(true);
    selectedData();
  };
  const handleInputChange = (e) => {
    setInputVal(e.target.value);
  };
  const handleSendClick = () => {
    const trimmedInput = inputVal?.trim();
    if (trimmedInput !== '' && inputVal !== '') {
      const newComment = {
        comment: inputVal,
        time: Date.now(),
        userName: userDetails?.user?.userName,
        email: userDetails?.user?.email,
        role: userDetails?.role
      };
      setComment((prevComments) => [...prevComments, newComment]);
      const mergedComments = [...commentArray, newComment];
      setCommentArray(mergedComments);
      setInputVal('');
    }
  };
  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  }, [commentArray]);

  const style = {
    position: 'absolute',
    bottom: isDrawerOpen ? '280px' : '20px',
    right: '20px',
    marginTop: 30,
    width: 400,
    bgcolor: Colors.Canvas_BG,
    borderRadius: '20px',
    padding: '20px',
    zIndex: '999'
  };
  return (
    <div>
      <Button
        variant="outlined"
        onClick={(e) => handleOpen(e)}
        sx={{
          fontSize: '.8em',
          textTransform: 'none',
          borderRadius: '10px',
          width: { xs: '5rem', sm: '8rem' },
          padding: { xs: 0, sm: 'auto' },
          height: '2rem',
          color: Colors.WHITE,
          marginRight: '1rem',
          '&:disabled': {
            color: 'gray',
            cursor: 'not-allowed'
          },
          '&:hover': {
            background: 'none'
          }
        }}
        startIcon={
          <ChatOutlined
            sx={{
              display: { xs: 'none', sm: 'block' },
              color: Colors.DARKBLUE
            }}
          />
        }>
        {COMMENTS}
      </Button>
      <Modal
        open={open}
        ref={popupRef}
        style={{ position: 'initial' }}
        hideBackdrop
        disableEnforceFocus
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            ...style,
            width: { xs: '80%', sm: '40vw', md: '25vw', lg: '22vw' }
          }}>
          <Grid
            container
            sx={{
              alignItems: 'center',
              marginBottom: '.5em'
            }}>
            <Grid item xs={10.5}>
              <Typography
                sx={{
                  color: Colors.WHITE,
                  fontSize: '1rem',
                  textAlign: 'center',
                  paddingLeft: { sm: '3.5em', xs: '1.5em' }
                }}>
                {COMMENTS}
              </Typography>
            </Grid>
            <Grid
              item
              xs={1.5}
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}>
              <Close
                sx={{ fontSize: '20px', color: Colors.LIGHTGREY, cursor: 'pointer' }}
                onClick={handleClose}
              />
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '35vh',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '.1em',
                height: '0em',
                borderRadius: '20px',
                scrollbarWidth: 'overlay',
                scrollbarColor: '#fff transparent'
              }
            }}
            ref={scrollContainerRef}>
            {loading ? (
              <Grid
                container
                sx={{ alignItems: 'center', justifyContent: 'center', height: '35vh' }}>
                <CircularProgress size={22} sx={{ color: Colors.DARKBLUE }} />
              </Grid>
            ) : isEmpty(commentArray) ? (
              <Grid
                container
                sx={{ alignItems: 'center', justifyContent: 'center', height: '35vh' }}>
                <Box
                  sx={{
                    backgroundColor: Colors.NAV_BLACK,
                    padding: '.5em',
                    borderRadius: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '.8em',
                    color: Colors?.TEXTGREY
                  }}>
                  <ErrorOutline
                    sx={{ color: Colors.TEXTGREY, marginRight: '.3em', fontSize: '1rem' }}
                  />
                  {EMPTY_COMMENTS}
                </Box>
              </Grid>
            ) : (
              commentArray?.map((comment) => {
                return (
                  <Grid item sx={{ display: 'flex', marginTop: '1rem' }}>
                    <Gravatar
                      email={comment?.email}
                      style={{
                        marginTop: '.1em',
                        order: '2px solid #1AACAC',
                        height: '2.25em',
                        width: '2.25em',
                        borderRadius: '50%'
                      }}
                    />
                    <Grid
                      item
                      sx={{
                        height: 'max-content',
                        marginLeft: '.5em',
                        p: '.3rem .6rem',
                        borderRadius: '10px',
                        backgroundColor: Colors.NAV_BLACK,
                        maxWidth: '60%',
                        minWidth: '30%'
                      }}>
                      <Typography
                        sx={{ fontSize: '.9rem', color: Colors.WHITE, marginBottom: '.1rem' }}>
                        {comment?.userName}
                      </Typography>
                      <Typography sx={{ fontSize: '.8rem', color: Colors.TEXTGREY }}>
                        {comment?.comment}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '.5rem',
                          color: Colors.TEXTGREY,
                          float: 'right',
                          mt: '.1em'
                        }}>
                        {comment?.time &&
                          new Date(comment?.time).toLocaleTimeString('en-US', {
                            month: '2-digit',
                            day: '2-digit',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true
                          })}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })
            )}
          </Grid>
          <Grid container>
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', marginTop: '1em' }}>
              <input
                type="text"
                placeholder="Type Here"
                onChange={handleInputChange}
                value={inputVal}
                style={{
                  width: '-webkit-fill-available',
                  height: '2.5em',
                  outline: 'none',
                  paddingLeft: '2em',
                  paddingRight: '5em',
                  color: Colors.WHITE,
                  backgroundColor: Colors.NAV_BLACK,
                  border: 'none',
                  borderRadius: '0.5em',
                  position: 'relative'
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && e.target.tagName === 'INPUT') {
                    e.preventDefault();
                    handleSendClick();
                  }
                }}
              />
              <IconButton
                disabled={inputVal === '' || inputVal?.trim() === ''}
                onClick={handleSendClick}
                aria-label="Send"
                sx={{
                  color: Colors.DARKBLUE,
                  fontSize: '1.25em',
                  position: 'absolute',
                  backgroundColor: 'transparent',
                  right: '35px',
                  '&:disabled': {
                    color: 'gray',
                    cursor: 'not-allowed'
                  }
                }}>
                <Send />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
