import React, { useState } from 'react';

import { Grid, Card, Typography, IconButton, Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { PieChart } from '@mui/x-charts/PieChart';
import { isEmpty } from 'lodash';

import {
  DescriptionOutlined,
  NavigateNext,
  ChevronLeft,
  ErrorOutlineRounded
} from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../theme';
import { Colors } from '../config/default';
import PermissionDropdown from './dropdown';
import image from '../assets/notFound.png';
import { dashboardText } from '../constants/appConstants';

function PieChartCard({
  type,
  setType,
  showSelect,
  text,
  dataArray,
  status,
  setStatus,
  showAverage,
  description,
  averageVal,
  showStatus,
  option1,
  option2
}) {
  const { RECORD_NOT_FOUND } = dashboardText;
  const largeScreen = useMediaQuery('(min-width:2700px)');
  const extraLargeScreen = useMediaQuery('(min-width:2200px)');
  const [startIndex, setStartIndex] = useState(0);
  const limitedDataArray = dataArray?.slice(0, 20);
  const itemsPerPage = 7;
  const handleNext = () => {
    if (startIndex + itemsPerPage < limitedDataArray?.length) {
      setStartIndex(startIndex + itemsPerPage);
    }
  };

  const handlePrev = () => {
    if (startIndex - itemsPerPage >= 0) {
      setStartIndex(startIndex - itemsPerPage);
    }
  };
  const categories = {
    Tableau10: [
      '#24658D',
      '#429EB0',
      '#F1A230',
      '#E95050',
      '#4e79a7',
      '#f28e2c',
      '#e15759',
      '#76b7b2',
      '#59a14f',
      '#edc949',
      '#af7aa1',
      '#ff9da7',
      '#9c755f',
      '#bab0ab',
      '#1b9e77',
      '#d95f02',
      '#7570b3',
      '#e7298a',
      '#66a61e',
      '#e6ab02',
      '#a6761d',
      '#666666',
      '#7fc97f',
      '#beaed4',
      '#fdc086',
      '#ffff99',
      '#386cb0',
      '#f0027f',
      '#bf5b17',
      '#666666',
      '#377eb8',
      '#4daf4a',
      '#984ea3',
      '#ff7f00',
      '#ffff33',
      '#a65628',
      '#f781bf',
      '#999999'
    ]
  };

  const [colorScheme] = useState('Tableau10');

  return (
    <ThemeProvider theme={theme}>
      <Grid
        item
        xs={12}
        md={5.8}
        sx={{
          marginTop: { xs: '1rem', xl: '1rem' },
          height: { xl: 'max-content' }
        }}>
        <Card
          sx={{
            height: { xs: '26em', md: '23rem', xl: '32rem' },
            borderRadius: '20px',
            padding: '1rem 1rem 1rem 0rem'
          }}>
          <Grid
            item
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'space-between',
              paddingLeft: '1rem',
              flexWrap: 'wrap',
              gap: '1em'
            }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <DescriptionOutlined sx={{ color: Colors.TEXTGREY, marginRight: '.5em' }} />
              <Typography sx={{ fontSize: '.8rem', color: Colors.TEXTGREY }}>{text}</Typography>
            </div>

            <div style={{ display: 'flex', height: '1rem', alignItems: 'center' }}>
              {showAverage && (
                <div style={{ display: 'flex' }}>
                  <Typography
                    sx={{
                      fontSize: '1rem',
                      color: Colors.TEXTGREY,
                      marginRight: '.3rem'
                    }}>
                    {description}
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', color: Colors.LIGHTBLUE }}>
                    {averageVal}
                  </Typography>
                </div>
              )}
              {showStatus && (
                <PermissionDropdown
                  permission={status}
                  setPermission={setStatus}
                  question=""
                  option1="All"
                  option3="Pending"
                  option2="Approved"
                  option5="On Hold"
                  color={Colors.TEXTGREY}
                  toShow="dashboard"
                  status={true}
                  setStartIndex={setStartIndex}
                />
              )}
              {showSelect && (
                <PermissionDropdown
                  permission={type}
                  setPermission={setType}
                  question=""
                  option1={option1}
                  option2={option2}
                  color={Colors.TEXTGREY}
                  toShow="dashboard"
                  status={false}
                  setStartIndex={setStartIndex}
                />
              )}
            </div>
          </Grid>
          {!isEmpty(limitedDataArray) ? (
            <Grid container>
              <Grid
                container
                item
                xs={12}
                sm={6}
                style={{
                  marginTop: '2em'
                }}>
                <PieChart
                  series={[
                    {
                      data: limitedDataArray,
                      cx: extraLargeScreen ? 250 : 200,
                      cy: extraLargeScreen ? 200 : largeScreen ? 160 : 140
                    }
                  ]}
                  width={extraLargeScreen ? 480 : largeScreen ? 400 : 380}
                  height={extraLargeScreen ? 650 : 470}
                  colors={categories[colorScheme]}
                  slotProps={{
                    legend: { hidden: true }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ padding: '1em' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '1em',
                    height: '60%',
                    overflowY: 'scroll !important',
                    borderRadius: '15px',
                    backgroundColor: Colors.GREY,
                    position: 'relative'
                  }}>
                  <Grid
                    sx={{
                      height: '85%',
                      overflowY: 'auto'
                    }}>
                    {limitedDataArray
                      ?.slice(startIndex, startIndex + itemsPerPage)
                      ?.map((item, index) => (
                        <div
                          key={`${item.label}-${index}`}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: extraLargeScreen ? '20px 10px' : '10px 10px',
                            color: Colors.TEXTGREY
                          }}>
                          <div
                            style={{
                              width: '20px',
                              height: '20px',
                              backgroundColor: categories[colorScheme][item?.id],
                              marginRight: '5px'
                            }}
                          />
                          {`${item.label}`}
                        </div>
                      ))}
                  </Grid>
                  {limitedDataArray?.length > 7 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        position: 'absolute',
                        bottom: '10px',
                        right: '10px'
                      }}>
                      <IconButton
                        aria-label="prev"
                        disabled={startIndex - itemsPerPage < 0}
                        onClick={handlePrev}
                        color="primary">
                        <ChevronLeft />
                      </IconButton>
                      <IconButton
                        aria-label="next"
                        disabled={startIndex + itemsPerPage >= limitedDataArray?.length}
                        onClick={handleNext}
                        color="primary">
                        <NavigateNext />
                      </IconButton>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </Grid>
            </Grid>
          ) : (
            <Grid
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '90%',
                flexDirection: 'column'
              }}>
              <img alt="notFound" src={image} />
              <Box
                sx={{
                  backgroundColor: Colors.NAV_BLACK,
                  padding: '.5em',
                  borderRadius: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: { xs: '.7em', md: '1em' },
                  marginTop: '.5em',
                  color: Colors.TEXTGREY
                }}>
                <ErrorOutlineRounded
                  sx={{ color: Colors.TEXTGREY, marginRight: '.3em', fontSize: '1.3em' }}
                />
                {RECORD_NOT_FOUND}
              </Box>
            </Grid>
          )}
        </Card>
      </Grid>
    </ThemeProvider>
  );
}

export default PieChartCard;
