import React, { useEffect, useState } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import { Grid, Box, Typography, Modal, CircularProgress } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

import { Colors } from '../config/default';
import PaginationButtons from '../components/paginationButtons';
import GeneratePoCard from '../components/generatePoCard';
import { formatAndFloatNumber } from '../services/common';
import { autoCap } from '../services/validationService';
import { poInvoicesPage } from '../constants/appConstants';
import { getCodeDetails } from '../services/services';
import { isEmpty } from 'lodash';

const PoInvoiceList = ({ poValue }) => {
  const { PURCHASE_ORDER, INVOICES, INVOICE_PREVIEW, INVOICE_NOT_FOUND, NO_RESPONSE, HEADING } =
    poInvoicesPage;
  const smallScreen = useMediaQuery('(max-height:1400px)');
  const [loading, setLoading] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [cardOpen, setCardOpen] = useState(false);
  const [documentContent, setDocumentContent] = useState([]);
  const [documentLineItems, setDocumentLineItems] = useState([]);
  const [response, setResponse] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const documentContentMapped = documentContent
    ?.map((item) => {
      if (item?.name === 'Estimate_no') {
        return {
          name: 'Order no',
          value: item?.value
        };
      } else if (item?.name === 'Estimate_date') {
        return {
          name: 'Date',
          value: item?.value
        };
      } else if (item?.name === 'Expiration_date') {
        return null;
      } else {
        return {
          name: autoCap(item?.name),
          value: item?.value
        };
      }
    })
    .filter((item) => item !== null);

  const documentLineItemsMapped = documentLineItems?.map((item) => ({
    ...item,
    qty: formatAndFloatNumber(item?.qty),
    total: formatAndFloatNumber(item?.total),
    unit_price: formatAndFloatNumber(item?.unit_price)
  }));

  const lineItemsPerPage = 8;
  const remainingLineitems = documentLineItemsMapped?.length - lineItemsPerPage;
  const totalCardPages = Math?.ceil(remainingLineitems / lineItemsPerPage);
  const filteredArray = documentContentMapped?.filter((item) => item?.value !== '');
  const getPOInvoices = async () => {
    setLoading(true);
    const result = await getCodeDetails('purchaseOrder', poValue);
    if (result?.status === 200) {
      setResponse(result?.data?.payload?.data);
      setInvoices(result?.data?.payload?.data?.Invoices);
      setDocumentContent(result?.data?.payload?.data?.po?.standardContent);
      setDocumentLineItems(result?.data?.payload?.data?.po?.standardizeLineItems);
    }
    setLoading(false);
  };
  useEffect(() => {
    getPOInvoices();
  }, [poValue]);
  const handleImageClick = () => {
    setSelectedImage(!selectedImage);
  };
  const handleClick = (index) => {
    setImageIndex(0);
    setSelectedIndex(index);
  };
  const handleClose = () => {
    setCardOpen(false);
  };
  const handleCardOpen = () => {
    setCardOpen(true);
  };

  const slideRight = () => {
    const images = invoices[selectedIndex]?.convertedS3Keys || [];
    setImageIndex((imageIndex + 1) % images?.length);
  };
  const slideLeft = () => {
    const images = invoices[selectedIndex]?.convertedS3Keys || [];
    const nextIndex = imageIndex - 1;
    if (nextIndex < 0) {
      setImageIndex(images?.length - 1);
    } else {
      setImageIndex(nextIndex);
    }
  };
  const mainGrid = {
    padding: '.7rem',
    backgroundColor: Colors.NAV_BLACK,
    borderRadius: '10px',
    height: '98.5%'
  };
  return (
    <Grid item>
      <Typography
        sx={{ textAlign: 'center', fontSize: '1.25em', color: Colors.WHITE, mb: '.7rem' }}>
        {HEADING}
      </Typography>
      <Grid
        container
        gap={1}
        sx={{ overflowY: { xs: 'auto', lg: 'hidden' }, height: { xs: '81vh', xl: '56vh' } }}>
        {loading ? (
          <Grid container sx={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <CircularProgress size={25} sx={{ color: Colors.DARKBLUE }} />
          </Grid>
        ) : isEmpty(response) ? (
          <Grid container sx={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'max-content',
                color: Colors.TEXTGREY
              }}>
              <ErrorOutline
                sx={{ color: Colors.TEXTGREY, marginRight: '.3em', fontSize: '1.1em' }}
              />
              <Typography>{NO_RESPONSE}</Typography>
            </Box>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={5.9} lg={4.2} sx={{ ...mainGrid, overflowY: 'auto' }}>
              <Typography
                variant="paragraph"
                sx={{
                  color: Colors.TOURQUISE
                }}>
                {PURCHASE_ORDER}
              </Typography>
              <Grid
                item
                sx={{
                  marginTop: '1rem',
                  padding: '.5rem',
                  backgroundColor: Colors.Canvas_Left_BG,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '10px'
                }}>
                <Box
                  sx={{
                    width: { xs: '90%', sm: '60%', md: '90%', lg: '100%' }
                  }}>
                  <GeneratePoCard
                    textSize={{ xs: '8px', lg: '10px', xl: '12px' }}
                    height={{ xs: '47vh', md: '59vh', lg: '61vh', xl: '42vh' }}
                    parentHeight="100%"
                    cursor={smallScreen ? 'pointer' : 'auto'}
                    onClick={smallScreen ? handleCardOpen : handleCardOpen}
                    documentContentMapped={filteredArray}
                    documentLineItemsMapped={documentLineItemsMapped}
                    lineItemsPerPage={lineItemsPerPage}
                    totalCardPages={totalCardPages}
                    show={true}
                    width="auto"
                  />
                  <Modal
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    open={cardOpen}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <Grid
                      sx={{
                        height: { xs: '60vh', md: '67vh', xl: '50vh' },
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        transform:
                          documentLineItemsMapped?.length > 10 ? 'scale(1.20)' : 'scale(1.3)',
                        cursor: 'pointer'
                      }}
                      onClick={handleClose}>
                      <GeneratePoCard
                        textSize={{ xs: '8px', lg: '10px', xl: '12px' }}
                        cursor="pointer"
                        height={{ xs: '47vh', md: '60vh', xl: '45vh' }}
                        onClick={handleClose}
                        documentContentMapped={filteredArray}
                        documentLineItemsMapped={documentLineItemsMapped}
                        lineItemsPerPage={lineItemsPerPage}
                        totalCardPages={totalCardPages}
                        width={{ xs: '60vw', md: '20vw', xl: '16vw' }}
                        show={true}
                      />
                    </Grid>
                  </Modal>
                </Box>
              </Grid>
            </Grid>
            {isEmpty(invoices) ? (
              <Grid
                item
                xs={12}
                md={5.9}
                lg={7.6}
                sx={{
                  backgroundColor: Colors.NAV_BLACK,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  borderRadius: '10px',
                  height: '98%'
                }}>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 'max-content',
                    color: Colors.TEXTGREY
                  }}>
                  <ErrorOutline
                    sx={{ color: Colors.TEXTGREY, marginRight: '.3em', fontSize: '1.1em' }}
                  />
                  <Typography>{INVOICE_NOT_FOUND}</Typography>
                </Box>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} md={5.9} lg={3.5} sx={{ ...mainGrid }}>
                  <Typography variant="paragraph" sx={{ color: Colors.TOURQUISE }}>
                    {INVOICES}
                  </Typography>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      height: { xs: '70vh', xl: '48vh' },
                      marginTop: '1rem',
                      overflowY: 'auto'
                    }}>
                    {invoices?.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          marginTop: '.3rem',
                          cursor: 'pointer',
                          color: Colors.TEXTGREY,
                          padding: '.3rem',
                          backgroundColor:
                            selectedIndex === index ? Colors.DEEPBLUE : 'transparent',
                          fontSize: '.9rem',
                          borderRadius: '5px'
                        }}
                        onClick={() => handleClick(index)}>
                        <span
                          style={{
                            width: '20px',
                            textAlign: 'right',
                            color: selectedIndex === index ? Colors.WHITE : Colors.TOURQUISE
                          }}>
                          {`${index + 1}.`}
                        </span>
                        <span style={{ marginLeft: '10px', fontWeight: 'normal' }}>
                          {item?.originalFileS3Key}
                        </span>
                      </div>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={5.9} lg={3.9} sx={{ ...mainGrid }}>
                  <Typography variant="paragraph" sx={{ color: Colors.TOURQUISE }}>
                    {INVOICE_PREVIEW}
                  </Typography>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      padding: '.5rem',
                      backgroundColor: Colors.Canvas_Left_BG,
                      marginTop: '1rem',
                      height: { xs: '70vh', xl: '48vh' },
                      objectFit: 'contain',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      borderRadius: '10px',
                      alignItems: 'center'
                    }}>
                    {invoices[selectedIndex]?.convertedS3Keys?.length > 0 && (
                      <img
                        className={`${selectedImage ? 'image-container enlarged' : ''}`}
                        src={invoices[selectedIndex]?.convertedS3Keys[imageIndex]}
                        alt={imageIndex}
                        style={{
                          cursor: 'pointer',
                          height: '97%',
                          objectFit: 'contain',
                          maxHeight: '100%',
                          maxWidth: '100%',
                          aspectRatio: 1
                        }}
                        onClick={handleImageClick}
                      />
                    )}
                  </Grid>
                  {invoices[selectedIndex]?.convertedS3Keys?.length > 1 && (
                    <PaginationButtons
                      disableFirstButton={imageIndex === 0}
                      disableSecondButton={
                        imageIndex === invoices[selectedIndex]?.convertedS3Keys?.length - 1
                      }
                      slideLeft={slideLeft}
                      slideRight={slideRight}
                      index={imageIndex}
                      images={invoices[selectedIndex]?.convertedS3Keys}
                    />
                  )}
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default PoInvoiceList;
