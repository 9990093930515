import React from 'react';

import { Button, CircularProgress } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import theme from '../theme';

export default function Btn({
  disabled,
  color,
  endIcon,
  onClick,
  variant,
  type,
  text,
  loading,
  width,
  radius,
  position,
  FONT,
  marginTop,
  height,
  right,
  float,
  marginBottom,
  setIsHovered,
  backgroundColor,
  cursor,
  disabledColor,
  startIcon,
  smallScreenFont,
  marginRight
}) {
  const disabledStyle = {
    pointerEvents: 'none'
  };
  const styleClass = {};
  return (
    <ThemeProvider theme={theme}>
      <Button
        disabled={disabled || loading}
        style={cursor === false ? disabledStyle : styleClass}
        sx={{
          backgroundColor: backgroundColor ? backgroundColor : '',
          width: width ? width : '100%',
          height: height ? height : '2.5rem',
          textTransform: 'unset !important',
          color: color,
          marginRight: marginRight,
          borderRadius: radius,
          borderColor: color,
          '&:hover': {
            background: backgroundColor
              ? backgroundColor
              : 'linear-gradient(to bottom right, #1AACAC, #2E97A7)',
            border: 'none'
          },
          '&:disabled': {
            backgroundColor: disabledColor || '#282828'
          },
          position: position,
          top: position && height ? '250px' : position ? '240px' : '',
          fontSize: { xs: smallScreenFont || '15px', lg: FONT ? FONT : '' },
          marginTop: marginTop ? marginTop : '',
          right: right,
          float: float ? float : '',
          marginBottom: marginBottom ? marginBottom : ''
        }}
        onMouseEnter={() => {
          setIsHovered ? setIsHovered(true) : '';
        }}
        onMouseLeave={() => {
          setIsHovered ? setIsHovered(false) : '';
        }}
        type={type}
        variant={variant}
        endIcon={endIcon}
        startIcon={startIcon}
        onClick={onClick}>
        {loading ? <CircularProgress size={25} color="inherit" /> : text}
      </Button>
    </ThemeProvider>
  );
}
