import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Grid, Card, Typography, Button, CircularProgress, Modal } from '@mui/material';
import {
  ArrowBack,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  DashboardOutlined,
  InsertDriveFileOutlined,
  Attachment,
  CorporateFare
} from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useToast } from '../toast/toastContext';
import { Colors } from '../config/default';
import Layout from '../components/layout';
import { userRoles } from '../constants/appConstants';
import { parentTabs, AdminParentTabs, getTodaysDate, clientParentTabs } from '../services/common';
import { getDocumentDetails, uploadFiles } from '../services/services';
import { documentDetails, generatePo } from '../constants/appConstants';
import { autoCap } from '../services/validationService';
import GeneratePoCard from '../components/generatePoCard';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import LineitemsDrawer from '../components/lineitemsDrawer';

export default function GeneratePO() {
  const [activeTab, setActiveTab] = useState('');
  const [vendorName, setVendorName] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [documentContent, setDocumentContent] = useState([]);
  const [documentLineItems, setDocumentLineItems] = useState([]);
  const [standardizeLineItems, setStandardizeLineItems] = useState([]);
  const [originalQuote, setOriginalQuote] = useState([]);
  const [open, setOpen] = useState(false);
  const [cardOpen, setCardOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(originalQuote.length / 1);
  const indexOfLastItem = currentPage * 1;
  const indexOfFirstItem = indexOfLastItem - 1;
  const currentItems = originalQuote?.slice(indexOfFirstItem, indexOfLastItem);

  const { id } = useParams();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const { BACK } = documentDetails;
  const { ACCOUNTANT, CLEVEL, MANAGER, ADMIN, OWNER, VENDOR } = userRoles;
  const { DOCUMENT_CONTENT } = generatePo;

  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  let accountantParentTabs = [
    {
      name: 'Dashboard',
      icon: <DashboardOutlined />
    },
    {
      name: 'Documents',
      icon: <InsertDriveFileOutlined />
    },
    { name: 'Attachments', icon: <Attachment /> }
  ];
  if (userDetails?.user?.erpType === 'Foundation') {
    accountantParentTabs = [
      ...accountantParentTabs,
      { name: 'Integrations', icon: <CorporateFare /> }
    ];
  }
  const extraLargeScreen = useMediaQuery('(min-width:2800px)');
  const largeScreen = useMediaQuery('(min-width:2000px) and (max-width:2800px)');

  const handleBackward = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleForward = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  function generateUniqueId() {
    const randomNumber = Math.floor(Math.random() * 1000);
    const timestamp = new Date().getTime();
    const uniqueId = `${timestamp}${randomNumber}`;
    return uniqueId.substring(0, 9);
  }
  const estimateId = generateUniqueId();
  const estimateNumber = estimateId;

  const callSource = userRole === 'Vendor' ? 'api' : 'app';

  useEffect(async () => {
    setIsLoading(true);
    const res = await getDocumentDetails(id, callSource);
    if (res?.status === 200) {
      setIsLoading(false);
      setDocumentContent(res?.data?.payload?.data?.document?.standardContent);
      setDocumentLineItems(res?.data?.payload?.data?.document?.standardizeLineItems);
      setStandardizeLineItems(res?.data?.payload?.data?.document?.lineItems);
      setOriginalQuote(res?.data?.payload?.data?.document?.convertedS3Keys);
    } else if (res?.response?.status === 403) {
      navigate('/unauthorized');
    }
  }, []);

  useEffect(() => {
    const vendorItem = documentContent.find((item) => item?.name === 'vendor');
    if (vendorItem) {
      setVendorName(vendorItem.value);
    }
  }, [documentContent]);

  const documentContentMapped = documentContent
    ?.map((item) => {
      if (item?.name === 'Estimate_no') {
        return {
          name: 'Order no',
          value: item?.value?.replace(/[^a-zA-Z0-9]/g, '')
        };
      } else if (item?.name === 'Estimate_date') {
        return {
          name: 'Date',
          value: item?.value
        };
      } else if (item?.name === 'Expiration_date') {
        return null;
      } else {
        return {
          name: autoCap(item?.name),
          value: item?.value
        };
      }
    })
    .filter((item) => item !== null);

  const documentLineItemsMapped = documentLineItems;

  const lineItemsPerPage = 12;
  const remainingLineitems = documentLineItemsMapped?.length - lineItemsPerPage;
  const totalCardPages = Math?.ceil(remainingLineitems / lineItemsPerPage);

  const captureAndUploadScreenshot = async () => {
    setLoading(true);
    const screenshots = [];
    const poElement = document.getElementById('Po0');
    try {
      if (documentLineItemsMapped.length <= 10) {
        const poBlob = await domtoimage?.toBlob(poElement);
        const poScreenshotFile = new File([poBlob], `${vendorName}Po.png`, { type: 'image/png' });
        screenshots?.push(poScreenshotFile);
      }

      if (documentLineItemsMapped.length > lineItemsPerPage) {
        const pdf = new jsPDF();
        for (let index = 0; index < totalCardPages + 1; index++) {
          const poEElement = document.getElementById(`Po${index}`);
          const poEBlob = await domtoimage?.toBlob(poEElement);
          const poScreenshotURL = URL?.createObjectURL(poEBlob);
          pdf?.addImage(
            poScreenshotURL,
            'png',
            0,
            0,
            pdf?.internal?.pageSize?.getWidth(),
            pdf?.internal?.pageSize?.getHeight()
          );
          if (index != totalCardPages) {
            pdf?.addPage();
          }
        }
        const pdfBinaryString = pdf.output('arraybuffer');
        const uint8Array = new Uint8Array(pdfBinaryString);
        const pdfFile = new File([uint8Array], `${vendorName}Po.pdf`, {
          type: 'application/pdf'
        });
        screenshots?.push(pdfFile);
      }

      const result = await uploadFiles(screenshots, 'Po', id);
      if (result?.status === 200) {
        showToast(result?.data?.metadata?.message, 'success');
        navigate('/');
      } else {
        showToast(
          result?.data?.metadata?.message || result?.response?.data?.metadata?.message,
          'error'
        );
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCardOpen = () => {
    setCardOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCardOpen(false);
  };

  return (
    <Grid
      xs={12}
      container
      sx={{ height: '100vh', backgroundColor: Colors.BG_BLACK, overflow: 'hidden' }}>
      <Layout
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        currentTabs={
          userRole === OWNER
            ? parentTabs
            : userRole === ACCOUNTANT
            ? accountantParentTabs
            : userRole === ADMIN
            ? AdminParentTabs
            : userRoles === VENDOR
            ? clientParentTabs
            : accountantParentTabs
        }
        path="Details"
        show={userDetails?.role === OWNER ? 'showUser' : ''}
      />

      <Grid
        xs={12}
        sx={{
          mt: { xs: '2em', md: '0' },
          display: 'flex',
          justifyContent: 'space-between'
        }}>
        <Button
          variant="outlined"
          sx={{
            border: `1px solid ${Colors.DARKBLUE}`,
            borderRadius: '10px',
            height: '2rem',
            width: '5rem',
            ml: { sm: '6.25em', xs: '3em' },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '.8em',
            textTransform: 'none',
            color: Colors.WHITE,
            '&:hover': {
              background: 'none',
              border: `1px solid ${Colors.DARKBLUE}`
            }
          }}
          onClick={() =>
            userDetails?.role === MANAGER || userDetails?.role === CLEVEL
              ? navigate('/approver')
              : userDetails?.role === ACCOUNTANT
              ? navigate('/accountant')
              : userDetails?.role === ADMIN
              ? navigate('/admin')
              : userDetails?.role === VENDOR
              ? navigate('/vendor')
              : navigate('/home')
          }>
          <ArrowBack sx={{ color: Colors.DARKBLUE, fontSize: '1.4em', mr: '.3em' }} />
          {BACK}
        </Button>
      </Grid>
      {isLoading ? (
        <Grid
          container
          sx={{
            height: '80vh',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <CircularProgress size={150} style={{ color: Colors.TOURQUISE }} />
        </Grid>
      ) : (
        <Grid
          container
          sx={{
            height: { xs: 'auto', md: '80vh' },
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'flex-start',
            paddingLeft: { xs: '0em', sm: '5em' },
            gap: '2em',
            backgroundColor: Colors.BG_BLACK,
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '0em',
              height: '0em',
              borderRadius: '20px'
            }
          }}>
          <Card
            sx={{
              height: '67vh',
              mt: { xs: '2em', md: '0' },
              ml: { xs: '2em', md: '0' },
              width: largeScreen
                ? '24vw'
                : extraLargeScreen
                ? '22vw'
                : { xs: '70%', md: '35%', lg: '22em' },
              backgroundColor: Colors.GREY,
              boxShadow: 'none',
              borderRadius: '1em',
              padding: '1.5em 1em'
            }}>
            <Grid
              sx={{
                height: '88%'
              }}>
              <Grid
                sx={{
                  color: Colors.TEXTGREY
                }}>
                <Typography
                  sx={{
                    color: Colors.WHITE,
                    fontSize: largeScreen ? '20px' : extraLargeScreen ? '30px' : '.8em',
                    fontWeight: '600',
                    mb: '1.5em',
                    ml: '1em'
                  }}>
                  {DOCUMENT_CONTENT}
                </Typography>
                {documentContentMapped?.map((item, index) => (
                  <Grid
                    key={index}
                    container
                    xs={12}
                    sx={{
                      padding: '0em 1em',
                      justifyContent: 'space-between',
                      borderRadius: '10px',
                      alignItems: 'center',
                      color: Colors.TEXTGREY,
                      height: largeScreen ? '4em' : extraLargeScreen ? '5em' : '2.5em',
                      backgroundColor: index % 2 === 0 ? Colors.NAV_BLACK : 'transparent'
                    }}>
                    <Grid xs={4}>
                      <Typography
                        sx={{
                          fontSize: largeScreen ? '18px' : extraLargeScreen ? '25px' : '.8em'
                        }}>
                        {item?.name === 'Order no'
                          ? item?.name?.replace('Order no', 'Quote No')
                          : item?.name}
                      </Typography>
                    </Grid>
                    <Grid xs={7} sx={{ textAlign: 'right' }}>
                      <Typography
                        sx={{
                          fontSize: largeScreen ? '18px' : extraLargeScreen ? '25px' : '.8em'
                        }}>
                        {item?.name === 'Date' ? getTodaysDate() : item?.value || '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            {documentLineItemsMapped?.length > 0 && (
              <LineitemsDrawer
                lineitems={standardizeLineItems}
                isConstructionCompany={false}
                annotationType="Estimate"
              />
            )}
            <Button
              disabled={loading}
              onClick={captureAndUploadScreenshot}
              variant="contained"
              sx={{
                width: '100%',
                mt: '.75em',
                borderRadius: '10px',
                height: largeScreen ? '3rem' : extraLargeScreen ? '4rem' : '2rem'
              }}>
              {loading ? <CircularProgress size={18} color="inherit" /> : 'Upload'}
            </Button>
          </Card>
          <div>
            <Grid
              sx={{
                margin: { xs: '0em 2em', sm: '0' },
                position: 'relative'
              }}>
              <GeneratePoCard
                textSize={{ xs: '10px', lg: '12px', xl: '14px' }}
                height="67vh"
                cursor="pointer"
                onClick={handleCardOpen}
                estimateNumber={estimateNumber}
                documentContentMapped={documentContentMapped}
                documentLineItemsMapped={documentLineItemsMapped}
                lineItemsPerPage={lineItemsPerPage}
                totalCardPages={totalCardPages}
              />
            </Grid>
          </div>
          <div>
            {currentItems?.map((s3Key, index) => (
              <img
                onClick={handleOpen}
                src={s3Key}
                alt=""
                style={{
                  cursor: 'pointer',
                  borderRadius: '10px',
                  height: 'calc(67vh + 3em)',
                  width: 'auto',
                  objectFit: 'contain'
                }}
              />
            ))}
            {totalPages > 1 ? (
              <div
                style={{
                  display: 'flex',
                  gap: '5px',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <Button
                  sx={{ backgroundColor: Colors.NAV_BLACK }}
                  onClick={handleBackward}
                  disabled={currentPage === 1}>
                  <KeyboardArrowLeft style={{ color: Colors.WHITE }} />
                </Button>
                <Typography sx={{ color: Colors.WHITE }}>
                  {`${currentPage} of ${totalPages}`}
                </Typography>

                <Button
                  sx={{ backgroundColor: Colors.NAV_BLACK }}
                  onClick={handleForward}
                  disabled={currentPage === totalPages}>
                  <KeyboardArrowRight style={{ color: Colors.WHITE }} />
                </Button>
              </div>
            ) : (
              ''
            )}
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                cursor: 'pointer'
              }}
              onClick={handleClose}>
              {currentItems?.map((s3Key, index) => (
                <img
                  onClick={handleClose}
                  src={s3Key}
                  alt=""
                  style={{
                    cursor: 'pointer',
                    borderRadius: '10px',
                    height: totalPages > 1 ? '95vh' : '100vh',
                    width: 'auto'
                  }}
                />
              ))}
              {totalPages > 1 ? (
                <div
                  style={{
                    display: 'flex',
                    gap: '5px',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <Button
                    sx={{ backgroundColor: Colors.NAV_BLACK }}
                    onClick={handleBackward}
                    disabled={currentPage === 1}>
                    <KeyboardArrowLeft style={{ color: Colors.WHITE }} />
                  </Button>
                  <Typography sx={{ color: Colors.WHITE }}>
                    {`${currentPage} of ${totalPages}`}
                  </Typography>

                  <Button
                    sx={{ backgroundColor: Colors.NAV_BLACK }}
                    onClick={handleForward}
                    disabled={currentPage === totalPages}>
                    <KeyboardArrowRight style={{ color: Colors.WHITE }} />
                  </Button>
                </div>
              ) : (
                ''
              )}
            </div>
          </Modal>
          <Modal
            open={cardOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <div
              style={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                transform: documentLineItemsMapped?.length > 10 ? 'scale(1.20)' : 'scale(1.3)',
                cursor: 'pointer'
              }}
              onClick={handleClose}>
              <GeneratePoCard
                textSize={{ xs: '10px', lg: '10px', xl: '12px' }}
                cursor="pointer"
                height="67vh"
                onClick={handleClose}
                estimateNumber={estimateNumber}
                documentContentMapped={documentContentMapped}
                documentLineItemsMapped={documentLineItemsMapped}
                lineItemsPerPage={lineItemsPerPage}
                totalCardPages={totalCardPages}
              />
            </div>
          </Modal>
        </Grid>
      )}
    </Grid>
  );
}
