import React from 'react';

import { Button, CircularProgress, Menu, MenuItem, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { Colors } from '../config/default';
import { isEmpty } from 'lodash';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    '&::-webkit-scrollbar': {
      width: '0.2em',
      height: '0em',
      borderRadius: '20px'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
      outline: '1px solid slategrey'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#fff',
      outline: '1px solid slategrey'
    },
    borderRadius: '10px',
    backgroundColor: '#282828',
    marginTop: theme.spacing(1),
    width: '90%',
    [theme.breakpoints.up('lg')]: {
      width: '22%'
    },

    [theme.breakpoints.up('xl')]: {
      width: '18%'
    },

    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '.5em'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: Colors.NAV_BLACK,
        borderRadius: '8px'
      },
      '&:hover': {
        backgroundColor: Colors.NAV_BLACK,
        borderRadius: '8px'
      }
    }
  }
}));

export default function CustomSelect({
  disabled,
  handleClose,
  anchorEl,
  setAnchorEl,
  options,
  text,
  loadingCheckbox,
  role,
  height,
  backgroundColor,
  width,
  variant,
  show,
  selectWidth
}) {
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div>
      <Button
        fullWidth
        disabled={loadingCheckbox || disabled}
        variant={variant || 'contained'}
        sx={{
          textTransform: 'unset !important',
          marginTop: variant ? 'auto' : '1em',
          backgroundColor: backgroundColor ? backgroundColor : Colors.NAV_BLACK,
          color: !variant && (isEmpty(role) || role === undefined) ? '#a7a7a7' : Colors.TEXTGREY,
          height: height ? height : '3.5em',
          borderRadius: '10px',
          borderColor: Colors.TOURQUISE,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: selectWidth || null,
          '&:hover': {
            borderColor: Colors.TOURQUISE,
            backgroundColor: Colors.NAV_BLACK
          },
          '&:disabled': {
            borderColor: Colors.GREY,
            backgroundColor: backgroundColor ? backgroundColor : Colors.NAV_BLACK
          }
        }}
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick}>
        {loadingCheckbox ? (
          <CircularProgress size={25} color="inherit" />
        ) : (
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {isEmpty(role) || role === undefined || show === 'syncCall' ? text : role}
            <KeyboardArrowDownIcon sx={{ color: '#A7A7A7A' }} />
          </Grid>
        )}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button'
        }}
        sx={{ maxHeight: '400px', width: width || '100%' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        {options?.map((option) => (
          <MenuItem
            key={show === 'syncCall' ? option?.value : option}
            value={show === 'syncCall' ? option?.value : option}
            onClick={(event) => handleClose(event, show === 'syncCall' ? option?.value : option)}
            disableRipple
            data-value={option}>
            {show === 'syncCall' ? option?.name : option}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}
