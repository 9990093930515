import React, { useState } from 'react';
import { useToast } from '../toast/toastContext';

import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Modal,
  Fade,
  Grid,
  IconButton,
  CircularProgress
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { getVendorsDocument } from '../services/services';
import { AdminTable, documentCommentsText } from '../constants/appConstants';
import { isEmpty } from 'lodash';
import { Colors } from '../config/default';
import '../image.css';

export default function DocumentList({ documents }) {
  const { ALL_DOCS } = AdminTable;
  const { IMAGE_NOT_AVAILABLE } = documentCommentsText;
  const { showToast } = useToast();
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  const [index, setIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const slideRight = () => {
    if (index === selectedImage.length - 1) {
      setIndex(0);
    } else {
      setIndex((index + 1) % selectedImage.length);
    }
  };

  const slideLeft = () => {
    const nextIndex = index - 1;
    if (index === 0) {
      setIndex(selectedImage.length - 1);
    } else {
      if (nextIndex < 0) {
        setIndex(selectedImage.length - 1);
      } else {
        setIndex(nextIndex);
      }
    }
  };
  const handleButtonClicked = (event) => {
    event.stopPropagation();
  };
  const handleClick = async (id) => {
    setIsLoading(true);
    setOpen(true);
    const result = await getVendorsDocument(id);
    if (result.status === 200) {
      if (isEmpty(result?.data?.payload?.data?.s3SingedUrl)) {
        showToast(IMAGE_NOT_AVAILABLE, 'error');
        setOpen(false);
      } else {
        setSelectedImage(result?.data?.payload?.data?.s3SingedUrl);
      }
    }
    setIsLoading(false);
  };

  const handleClose = () => {
    setSelectedImage(null);
    setOpen(false);
    setIndex(0);
  };

  return (
    <>
      <Typography variant="h5" sx={{ marginLeft: '1rem', color: Colors.TOURQUISE }}>
        {ALL_DOCS}
      </Typography>
      <List
        sx={{
          width: '100%',
          position: 'relative',
          height: 500,
          '& ul': { padding: 0 },
          overflowY: 'auto'
        }}>
        {documents?.map((item) => (
          <ListItem key={item._id}>
            <ListItemText
              primary={item?.s3FileKey}
              sx={{ cursor: 'pointer', color: Colors.TEXTGREY }}
              onClick={() => handleClick(item._id)}
            />
          </ListItem>
        ))}
      </List>
      <Modal open={open} onClose={handleClose} closeAfterTransition>
        <Fade in={open}>
          <div onClick={handleClose} style={{ cursor: 'pointer' }}>
            <Grid container>
              {isLoading ? (
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    right: '50%'
                  }}>
                  <CircularProgress size={90} color="primary" />
                </div>
              ) : (
                selectedImage?.length > 0 && (
                  <>
                    {selectedImage?.length > 1 ? (
                      <IconButton
                        sx={{
                          zIndex: 10000,
                          position: 'absolute',
                          cursor: 'pointer',
                          marginRight: '1rem',
                          color: 'white',
                          left: '25%',
                          backgroundColor: '#13293E',
                          top: '50%',
                          '&:hover': {
                            background: '#13293E'
                          },
                          '&:disabled': {
                            background: '#13293E',
                            color: 'gray',
                            opacity: '0.6',
                            cursor: 'not-allowed'
                          }
                        }}
                        onClick={(event) => {
                          handleButtonClicked(event);
                          slideLeft();
                        }}>
                        <ArrowBackIosNewIcon />
                      </IconButton>
                    ) : (
                      ''
                    )}
                    <div className={`image-container enlarged`}>
                      <img id="i" src={selectedImage[index]} alt={index} className="image" />
                    </div>
                    {selectedImage?.length > 1 ? (
                      <IconButton
                        sx={{
                          cursor: 'pointer',
                          color: 'white',
                          zIndex: 10000,
                          position: 'absolute',
                          top: '50%',
                          right: '25%',
                          backgroundColor: '#13293E',
                          '&:hover': {
                            background: '#13293E'
                          },
                          '&:disabled': {
                            background: '#13293E',
                            color: 'gray',
                            opacity: '0.6',
                            cursor: 'not-allowed'
                          }
                        }}
                        onClick={(event) => {
                          handleButtonClicked(event);
                          slideRight();
                        }}>
                        <ArrowForwardIosIcon />
                      </IconButton>
                    ) : (
                      ''
                    )}
                  </>
                )
              )}
            </Grid>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
