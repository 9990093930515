import React, { useState } from 'react';
import { Button, styled, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Colors } from '../config/default';
import Prompt from './prompt';
import { HtmlTooltip } from '../services/common';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '10px',
    backgroundColor: '#282828',
    marginTop: theme.spacing(1),
    minWidth: 160,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '.5em'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: Colors.NAV_BLACK,
        borderRadius: '8px'
      },
      '&:hover': {
        backgroundColor: Colors.NAV_BLACK,
        borderRadius: '8px'
      }
    }
  }
}));

export default function ButtonDropdown({
  disable,
  text,
  icon,
  options,
  showPrompt,
  setOpenPrompt,
  openPrompt,
  width,
  helperText,
  loading
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item) => {
    setSelectedOption(item);
    showPrompt ? setOpenPrompt(true) : item?.action();
    handleClose();
  };

  return (
    <>
      <HtmlTooltip
        title={
          <Typography color="inherit" sx={{ fontSize: '1em' }}>
            {helperText}
          </Typography>
        }>
        <Button
          disabled={disable}
          sx={{
            width: width,
            color: Colors.DARKBLUE,
            fontSize: { xs: '10px', lg: '17px' },
            backgroundColor: 'none',
            borderRadius: '5px',
            textTransform: 'unset !important',
            height: '2.5rem',
            '&:disabled': {
              backgroundColor: 'none',
              color: Colors.LIGHTGREY
            }
          }}
          onClick={handleClick}
          endIcon={icon}
          variant="standard">
          {text}
        </Button>
      </HtmlTooltip>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button'
        }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}>
        {options?.map(
          (item, index) =>
            item?.showButton && (
              <MenuItem
                key={index}
                sx={{ color: Colors.WHITE }}
                disabled={item?.disable}
                onClick={() => handleMenuItemClick(item)}>
                {item?.name}
              </MenuItem>
            )
        )}
      </StyledMenu>

      {showPrompt && selectedOption && (
        <Prompt
          title={selectedOption?.promptTitle}
          description={selectedOption?.promptDescription}
          leftButton="Cancel"
          rightButton="Confirm"
          setOpen={setOpenPrompt}
          open={openPrompt}
          handleClick={selectedOption?.promptFunction}
          loading={loading}
        />
      )}
    </>
  );
}
