import React, { useState, useEffect } from 'react';
import { useToast } from '../toast/toastContext';
import { List, ListItem, Typography, CircularProgress, Grid, Button, Box } from '@mui/material';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { getTaskQueue, clearQueue } from '../services/services';
import { isEmpty } from 'lodash';

import { Colors } from '../config/default';
import Prompt from './prompt';
import { QueuePopup } from '../constants/appConstants';

export default function Queue({ setModelOpen }) {
  const { showToast } = useToast();
  const { USER_QUEUE, NO_RECORD, PROGRESS, PENDING, NO_PENDING_TASK, CLEAR_QUEUE, QUEUE_MESSAGE } =
    QueuePopup;
  const [isLoading, setIsLoading] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState('');
  const [open, setOpen] = useState(false);
  const handleClearQueue = async () => {
    setLoading(true);
    const res = await clearQueue();
    if (res?.status === 200) {
      showToast(res?.data?.metadata?.message, 'success');
      setOpen(false);
      setModelOpen(false);
    } else {
      showToast(res?.data?.metadata?.message || res?.response?.data?.metadata?.message, 'error');
    }
    setLoading(false);
  };

  const handleGetQueue = async () => {
    setIsLoading(true);
    const result = await getTaskQueue();
    if (result?.status === 200) {
      setTasks(result?.data?.payload?.data?.queue);
      setTotal(result?.data?.payload?.data?.totalNumberOfTasks);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetQueue();
  }, []);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };
  const formatTask = (task) => {
    const hasDigits = /\d/.test(task);
    return hasDigits ? 'Create Document' : task;
  };
  const counts = [
    { label: 'Total Tasks:', value: total },
    { label: 'Pending Tasks:', value: total - 1 },
    { label: 'In Progress Tasks:', value: 1 }
  ];

  return (
    <>
      <Typography variant="h6" sx={{ color: Colors.WHITE }}>
        {USER_QUEUE}
      </Typography>
      <List
        sx={{
          width: '100%',
          height: 'max-content',
          backgroundColor: Colors.BACKGROUND,
          borderRadius: '8px'
        }}>
        {isLoading ? (
          <Grid
            container
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              height: '465px'
            }}>
            <CircularProgress size={60} color="primary" />
          </Grid>
        ) : isEmpty(tasks) ? (
          <Grid
            container
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              height: '465px',
              color: Colors.TEXTGREY
            }}>
            <ErrorOutline sx={{ color: Colors.TEXTGREY, marginRight: '.3em', fontSize: '1.2em' }} />
            <Typography>{NO_RECORD}</Typography>
          </Grid>
        ) : (
          <>
            <Grid
              container
              item
              xs={12}
              sx={{
                justifyContent: total?.toString()?.length > 4 ? 'flex-start' : 'center',
                mb: 1
              }}>
              {counts?.map((item, i) => {
                return (
                  <Box
                    key={i}
                    sx={{
                      display: 'flex',
                      padding: '.5rem',
                      border: '1px solid #2E97A7',
                      borderRadius: '7px',
                      minWidth: '7.5rem',
                      ml: 0.5,
                      mr: 0.5,
                      mt: 0.5
                    }}>
                    <Typography sx={{ color: Colors.WHITE, fontSize: '.8rem' }} variant="caption">
                      {item?.label}
                    </Typography>
                    <Typography
                      sx={{ color: Colors.TOURQUISE, fontSize: '.8rem' }}
                      variant="caption">
                      &nbsp; {item?.value}
                    </Typography>
                  </Box>
                );
              })}
            </Grid>
            <Box
              sx={{
                height: 355,
                mt: 2,
                mb: 2,
                display: 'flex',
                justifyContent: 'space-between'
              }}>
              <Grid
                item
                xs={5.9}
                sx={{
                  backgroundColor: Colors.DARKGRAY,
                  height: '100%',
                  borderRadius: '10px',
                  overflowY: 'auto',
                  p: 1.5
                }}>
                <Typography
                  variant="body2"
                  sx={{
                    color: Colors.TOURQUISE,
                    margin: '.5rem 0rem 1rem .2rem'
                  }}>
                  {PENDING}
                </Typography>
                {tasks?.length <= 1 && (
                  <Typography
                    variant="caption"
                    sx={{ color: Colors.TEXTGREY, margin: '1.5rem 0rem 0rem .2rem' }}>
                    {NO_PENDING_TASK}
                  </Typography>
                )}
                {tasks?.slice(1)?.map((item, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      padding: '0.5 1',
                      backgroundColor: Colors.Canvas_BG,
                      borderRadius: '5px',
                      mb: 1
                    }}>
                    <Typography
                      variant="caption"
                      sx={{
                        whiteSpace: 'normal',
                        overflowWrap: 'break-word',
                        color: Colors.TEXTGREY,
                        wordBreak: 'break-all'
                      }}>
                      {index + 1}. {formatTask(item?.operation)}
                    </Typography>
                  </ListItem>
                ))}
              </Grid>
              <Grid
                item
                xs={5.9}
                sx={{
                  backgroundColor: Colors.DARKGRAY,
                  height: '100%',
                  borderRadius: '10px',
                  p: 1.5
                }}>
                <Typography
                  variant="body2"
                  sx={{ color: Colors.TOURQUISE, margin: '.5rem 0rem 1rem .2rem' }}>
                  {PROGRESS}
                </Typography>
                <ListItem
                  sx={{
                    padding: '0.5 1',
                    backgroundColor: Colors.Canvas_BG,
                    borderRadius: '5px'
                  }}>
                  <Typography
                    variant="caption"
                    sx={{
                      whiteSpace: 'normal',
                      overflowWrap: 'break-word',
                      color: Colors.TEXTGREY,
                      wordBreak: 'break-all'
                    }}>
                    1. {formatTask(tasks[0]?.operation)}
                  </Typography>
                </ListItem>
                <Typography
                  variant="caption"
                  sx={{
                    color: Colors.TEXTGREY,
                    float: 'right',
                    marginRight: '.3rem',
                    whiteSpace: 'normal',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-all'
                  }}>
                  Synced: {`${tasks[0]?.syncedRecords} / ${tasks[0]?.totalRecords}`}
                </Typography>
              </Grid>
            </Box>
            <Grid item xs={12} sx={{ display: 'flex' }}>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  borderRadius: '10px',
                  backgroundColor: Colors.RED,
                  color: Colors.WHITE,
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: Colors.RED
                  }
                }}
                onClick={(e) => handleOpen(e)}>
                {CLEAR_QUEUE}
              </Button>
              <Prompt
                title={CLEAR_QUEUE}
                description={QUEUE_MESSAGE}
                leftButton="Cancel"
                rightButton="Clear"
                setOpen={setOpen}
                open={open}
                opacity={true}
                handleClick={handleClearQueue}
                loading={loading}
              />
            </Grid>
          </>
        )}
      </List>
    </>
  );
}
