import * as React from 'react';
import { InputLabel, FormControl, TextField, Autocomplete, Paper } from '@mui/material';
import { Colors } from '../config/default';
import { makeStyles } from '@mui/styles';

export default function BusinessAccountsList({
  permission,
  setPermission,
  question,
  color,
  data,
  allowInput,
  font,
  placeHolder
}) {
  const handleChange = (event, value) => {
    setPermission(value);
  };

  const handleInputChange = (event, value) => {
    if (allowInput) {
      setPermission(value);
    }
  };

  const useStyles = makeStyles((theme) => ({
    clearIndicator: {
      color: Colors.TEXTGREY
    },
    option: {
      borderRadius: '7px',
      '&:hover': {
        borderRadius: '7px',
        backgroundColor: '#141414 !important'
      },
      '&:active': {
        borderRadius: '7px'
      }
    }
  }));

  const classes = useStyles();

  return (
    <div>
      <FormControl fullWidth variant="standard">
        <InputLabel id="demo-simple-select-standard-label">{question}</InputLabel>
        <Autocomplete
          value={permission || null}
          onChange={handleChange}
          onInputChange={handleInputChange}
          options={data}
          disableClearable={font || !permission}
          classes={{ clearIndicator: classes.clearIndicator, option: classes.option }}
          ListboxProps={{
            className: 'myCustomList'
          }}
          sx={{
            '& .MuiAutocomplete-popupIndicator': {
              color: Colors.TEXTGREY
            }
          }}
          PaperComponent={({ children }) => (
            <Paper
              style={{
                backgroundColor: Colors.NAV_BLACK,
                color: Colors.TEXTGREY,
                borderRadius: '10px',
                padding: '0px 8px'
              }}>
              {children}
            </Paper>
          )}
          renderOption={(props, option) => {
            const { key } = props;
            return (
              <li {...props} key={props['data-option-index'] + '-' + key}>
                {option}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              placeholder={placeHolder && placeHolder}
              sx={{
                marginTop: '.3rem'
              }}
              {...params}
              variant="standard"
              label=""
              InputProps={{
                ...params.InputProps,
                sx: {
                  color: color,
                  fontSize: font || '13px'
                }
              }}
            />
          )}
        />
      </FormControl>
    </div>
  );
}
