import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Card, Grid, Typography, CircularProgress, Skeleton } from '@mui/material';

import {
  CheckCircleOutlined,
  InsertDriveFileOutlined,
  ErrorOutlineOutlined
} from '@mui/icons-material';

import { ThemeProvider } from '@mui/material/styles';
import theme from '../../theme';
import { Colors } from '../../config/default';
import { getDashboardRecord, getDashboardDocumentCount } from '../../services/services';
import { dashboardText } from '../../constants/appConstants';
import LineGraph from '../../components/lineGraph';
import PieChartCard from '../../components/pieChart';
import DateRangePickerWithButtons from '../../components/DatePicker';

function VendorDashboard({ startDate, endDate, setStartDate, setEndDate }) {
  const { AVERAGE_CHANGES, CHANGES, CHANGE, DOCUMENT_STATUS_RATIO, RESPONSE_TIME, DOC_TYPE } =
    dashboardText;
  const [resdata, setResData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [documentCount, setDocumentCount] = useState();
  const [documentCountLoader, setDocumentCountLoader] = useState(false);

  const navigate = useNavigate();

  const getData = async (start, end) => {
    setLoading(true);
    if (start && end) {
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 999);
      const utcStartDate = new Date(start.getTime() - start.getTimezoneOffset() * 60000);
      const utcEndDate = new Date(end.getTime() - end.getTimezoneOffset() * 60000);
      const result = await getDashboardRecord(
        utcStartDate?.toISOString(),
        utcEndDate?.toISOString(),
        'By Vendor',
        'api'
      );
      if (result.status === 200) {
        setResData(result?.data?.payload?.data);
      } else if (result?.response?.status === 401) {
        localStorage.clear();
        navigate('/');
      } else {
        setResData(null);
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    getData(startDate, endDate);
  }, [startDate, endDate]);
  const getDocumentCount = async () => {
    setDocumentCountLoader(true);
    const res = await getDashboardDocumentCount();
    if (res?.status === 200) {
      setDocumentCount(res?.data?.payload?.data);
    }
    setDocumentCountLoader(false);
  };
  useEffect(() => {
    getDocumentCount();
  }, []);
  const documentStatusRatio = resdata?.documentStatusRatio?.map((documentStatusPercentage, i) => ({
    id: i,
    value: documentStatusPercentage?.value,
    label: documentStatusPercentage?.label
  }));
  const ResponseTimeEachDocument = resdata?.ResponseTimeEachDocument?.map((timeTaken, i) => ({
    id: i,
    value: timeTaken?.value,
    label: timeTaken?.label
  }));

  const documentType = resdata?.documentType?.map((docType, i) => ({
    id: i,
    value: docType?.value,
    label: docType?.label
  }));

  const documents = [
    {
      status: 'Error',
      value: documentCount?.Error,
      icon: <ErrorOutlineOutlined sx={{ color: Colors.RED, marginRight: '.5em' }} />,
      color: Colors.RED
    },
    {
      status: 'Success',
      value: documentCount?.Success,
      icon: <CheckCircleOutlined sx={{ color: Colors.LIGHTBLUE, marginRight: '.5em' }} />,
      color: Colors.LIGHTBLUE
    },

    {
      status: 'Total',
      value: documentCount?.totalDocuments,
      icon: <InsertDriveFileOutlined sx={{ color: Colors.YELLOW, marginRight: '.5em' }} />,
      color: Colors.YELLOW
    }
  ];
  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{
          justifyContent: 'center',
          marginTop: '.7rem',
          height: { xs: '59vh', sm: '75vh' },
          overflowY: 'auto'
        }}>
        <Grid
          container
          item
          xs={12}
          sx={{
            paddingX: '2rem',
            marginTop: { xs: '1.25rem', sm: '.3rem' },
            flexWrap: 'wrap',
            gap: { xs: '1em', lg: '2em' }
          }}>
          {documents?.map((document, i) => {
            return (
              <Card
                key={i}
                sx={{
                  border: `2px solid ${document?.color}`,
                  borderRadius: '20px',
                  width: { xs: '100%', sm: '40%', lg: '13.35vw' },
                  height: '4.5rem',
                  padding: '1rem'
                }}>
                <div style={{ display: 'flex' }}>
                  {document?.icon}
                  <Typography sx={{ fontSize: '1rem', color: document?.color }}>
                    {document?.status}
                  </Typography>
                </div>
                {documentCountLoader ? (
                  <Skeleton style={{ height: '3rem', width: '3rem' }} animation="wave" />
                ) : (
                  <Typography sx={{ fontSize: '2rem' }}>{document?.value}</Typography>
                )}
              </Card>
            );
          })}
        </Grid>
        <Grid
          xs={12}
          container
          sx={{
            justifyContent: 'flex-end',
            marginTop: '1em',
            paddingRight: '2rem'
          }}>
          <DateRangePickerWithButtons
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </Grid>
        {loading || resdata === null ? (
          <Grid
            container
            sx={{
              height: '60vh',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <CircularProgress size={100} color="primary" />
          </Grid>
        ) : (
          <>
            <Grid
              container
              item
              xs={12}
              sx={{
                justifyContent: 'space-between',
                marginTop: '.6rem',
                gap: { xs: '1em', sm: '1em', md: '0' },
                paddingX: '2rem'
              }}>
              <LineGraph
                average={AVERAGE_CHANGES}
                averageVal={resdata?.documentActivityChanges?.avgActivityChanges}
                averageHeading={CHANGES}
                target={CHANGE}
                dataSet={resdata?.documentActivityChanges?.averageTimeByDate}
                xAxisType="date"
                xAxisKey="date"
                yAxisKey="averageChanges"
                dataKey="averageChanges"
              />
              <PieChartCard text={DOC_TYPE} dataArray={documentType} />
            </Grid>
            <Grid
              container
              item
              xs={12}
              sx={{
                justifyContent: { xs: 'space-between', xl: 'flex-start' },
                paddingX: '2rem',
                gap: { xl: '3.3%' }
              }}>
              <PieChartCard text={DOCUMENT_STATUS_RATIO} dataArray={documentStatusRatio} />
              <PieChartCard
                text={RESPONSE_TIME}
                dataArray={ResponseTimeEachDocument}
                showAverage={true}
                description="Average Time"
                averageVal={resdata?.totalAvg}
              />
            </Grid>
          </>
        )}
      </Grid>
    </ThemeProvider>
  );
}

export default VendorDashboard;
