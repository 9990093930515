import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Grid, CircularProgress, MenuItem, Select, Typography, Box } from '@mui/material';

import '../index.css';
import { mergePdf, getSelectedDocs } from '../services/services';
import { get_queue, get_tabs } from '../redux/actions/action';
import { Colors } from '../config/default';
import { useToast } from '../toast/toastContext';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../image.css';

function SplitDoc({ handleClose, splitDocData, batchId, getbatches, openSplit }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(true);
  const [myData, setMyData] = useState(splitDocData);
  const smallScreen = useMediaQuery('(min-width:446px) and (max-width:824px)');
  const extraSmallScreen = useMediaQuery('(max-width:445px)');
  const validImageData = splitDocData?.filter(
    (item) => item.s3_file_name !== '$$' && item.s3_file_name !== '##'
  );
  let options = ['Invoice', 'Quote', 'G702', 'Receipt', 'Delivery Ticket'];

  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const globalActiveTab = useSelector((state) => state?.tabs?.tabs);
  const callSource = userRole === 'Vendor' ? 'api' : 'app';
  const { showToast } = useToast();

  const [isEnlargedArray, setIsEnlargedArray] = useState(Array(validImageData?.length).fill(false));
  const handleImageClick = (index) => {
    const updatedEnlargedArray = [...isEnlargedArray];
    updatedEnlargedArray[index] = !updatedEnlargedArray[index];
    setIsEnlargedArray(updatedEnlargedArray);
  };
  const Tabs = {
    ...globalActiveTab,
    documentChildActiveTab: 'To Review'
  };
  useEffect(() => {
    const updatedData = [];
    splitDocData?.forEach((doc) => {
      let docType = doc?.docType;
      if (docType === 'Estimate') {
        docType = 'Quote';
      } else if (docType === 'Delivery Ticket') {
        docType = 'DeliveryTicket';
      } else if (docType === 'Other') {
        docType = 'Invoice';
      }

      doc?.files?.forEach((fileName, index) => {
        if (index === 0) {
          updatedData.push({
            s3_file_name: fileName,
            signedUrl: doc?.s3FileKeys[index],
            doc_type: docType
          });
        } else {
          updatedData.push({
            s3_file_name: '##',
            doc_type: ''
          });
          updatedData.push({
            s3_file_name: fileName,
            signedUrl: doc?.s3FileKeys[index],
            doc_type: ''
          });
        }
      });
      updatedData.push({
        s3_file_name: '$$',
        doc_type: ''
      });
    });
    if (updatedData?.length > 0) {
      updatedData.pop();
    }
    setMyData(updatedData);
  }, [splitDocData]);

  const handleSubmit = (item, index) => {
    const updatedData = [...myData];
    updatedData[index].s3_file_name = updatedData[index].s3_file_name === '$$' ? '##' : '$$';
    setMyData(updatedData);
    setReload(!reload);
  };

  const handleDropdownChange = (value, index) => {
    const updatedData = [...myData];
    updatedData[index].doc_type = value === '' ? 'Invoice' : value;
    setMyData(updatedData);
  };

  const handleClick = async () => {
    setLoading(true);
    let finalArrayData = [];
    let populateArray = [];
    let currentDocType = '';
    for (let x = 0; x < myData.length; x++) {
      const item = myData[x];
      if (item.s3_file_name !== '$$' && item.s3_file_name !== '##') {
        populateArray.push(item.s3_file_name);
        // Keep track of the docType (use the first file's docType in the group)
        if (!currentDocType) {
          currentDocType = item.doc_type;
          // Check and adjust the docType if necessary
          if (currentDocType === 'Quote') {
            currentDocType = 'Estimate';
          } else if (currentDocType === 'Delivery Ticket') {
            currentDocType = 'DeliveryTicket';
          }
        }
        if (x === myData.length - 1) {
          finalArrayData.push({ docType: currentDocType, files: populateArray });
        }
      }
      if (item.s3_file_name === '$$') {
        if (populateArray.length) {
          finalArrayData.push({ docType: currentDocType, files: populateArray });
          populateArray = [];
          currentDocType = ''; // Reset docType for the next group
        }
      }
      if (item.s3_file_name === '##') {
        // Include the next file as part of the current group
        populateArray.push(myData[x + 1]?.s3_file_name);
        // Keep the docType of the next file if it's set
        if (!currentDocType) {
          currentDocType = myData[x + 1]?.doc_type || '';
          if (currentDocType === 'Quote') {
            currentDocType = 'Estimate';
          } else if (currentDocType === 'Delivery Ticket') {
            currentDocType = 'DeliveryTicket';
          }
        }

        if (x === myData.length - 2) {
          finalArrayData.push({ docType: currentDocType, files: populateArray });
        }

        x++;
      }
    }

    const params = {
      data: finalArrayData,
      extractedDataId: batchId || ''
    };

    const result = await mergePdf(params);
    if (result?.data?.metadata?.status === 'SUCCESS') {
      showToast(result?.data?.metadata?.message, 'success');
      if (getbatches) {
        getbatches();
      }
      const response = await getSelectedDocs(callSource);
      dispatch(get_tabs(Tabs));
      dispatch(get_queue(response?.data?.payload?.data));
      handleClose();
    } else {
      showToast(result?.response?.data?.metadata?.message, 'error');
    }

    setLoading(false);
  };
  const infoBox = [
    { color: '#429EB0', text: 'File Joiner' },
    { color: '#E95050', text: 'File Separator' }
  ];
  return (
    <>
      <Grid
        container
        sx={{
          paddingTop: '0px'
        }}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Typography sx={{ fontSize: '18px', color: Colors.WHITE }}>Split PDF</Typography>
          <Grid
            container
            item
            sx={{
              padding: '0 3rem',
              flexDirection: 'column',
              alignItems: 'flex-end',
              color: Colors.WHITE
            }}>
            {infoBox?.map((item, index) => (
              <Box
                key={index}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box
                  sx={{
                    width: '15px',
                    height: '15px',
                    backgroundColor: item?.color,
                    borderRadius: '4px',
                    mr: 1
                  }}
                />
                <Typography>{item?.text}</Typography>
              </Box>
            ))}
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            height: { xs: '450px', xl: '720px' },
            overflowY: 'scroll',
            margin: '0rem 3rem',
            borderRadius: '20px',
            padding: '.3rem 1rem',
            border: '2px dashed #5B5B5B'
          }}>
          {openSplit ? (
            <Grid container sx={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}>
              <CircularProgress size={22} sx={{ color: Colors.DARKBLUE }} />
            </Grid>
          ) : (
            <>
              {myData?.map((item, index) => {
                const isSeparator = item.s3_file_name === '##' || item.s3_file_name === '$$';
                const isMerged = myData[index - 1]?.s3_file_name === '##';
                if (isSeparator || isMerged) {
                  item.doc_type = '';
                } else if (!item.doc_type) {
                  item.doc_type = 'Invoice';
                }
                return (
                  <Grid
                    item
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      height: 'max-content',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                      {!isSeparator && !isMerged && (
                        <Select
                          value={item.doc_type}
                          onChange={(e) => handleDropdownChange(e.target.value, index)}
                          sx={{ mb: 1, width: '150px', borderRadius: '10px', height: '2rem' }}>
                          {options.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </Grid>

                    {item.s3_file_name === '$$' ? (
                      <hr
                        style={{
                          border: 'none',
                          borderLeft: '8px solid #E95050',
                          height: smallScreen ? '290px' : extraSmallScreen ? '210px' : '300px',
                          margin: '0px',
                          cursor: 'pointer',
                          borderRadius: '30px',
                          marginTop: '2.5rem'
                        }}
                        onClick={() => {
                          handleSubmit(item, index);
                        }}
                      />
                    ) : item.s3_file_name === '##' ? (
                      <hr
                        style={{
                          border: 'none',
                          borderLeft: '8px solid #429EB0',
                          height: smallScreen ? '290px' : extraSmallScreen ? '210px' : '300px',
                          cursor: 'pointer',
                          margin: '0px',
                          borderRadius: '30px',
                          marginTop: '2.5rem'
                        }}
                        onClick={() => {
                          handleSubmit(item, index);
                        }}
                      />
                    ) : (
                      <Grid
                        item
                        className={`splitImage-container ${
                          isEnlargedArray[index] ? 'splitEnlarged' : ''
                        }`}
                        onClick={() => handleImageClick(index)}>
                        <img
                          id="i"
                          style={{
                            cursor: isEnlargedArray[index] ? 'zoom-out' : 'zoom-in',
                            width: isEnlargedArray[index]
                              ? 'auto'
                              : smallScreen
                              ? '250px'
                              : extraSmallScreen
                              ? '180px'
                              : '250px',
                            height: isEnlargedArray[index]
                              ? 'auto'
                              : smallScreen
                              ? '290px'
                              : extraSmallScreen
                              ? '210px'
                              : '300px',
                            marginTop: isMerged ? '2.5rem' : 'auto',
                            borderRadius: '10px'
                          }}
                          alt={item?.s3_file_name}
                          src={item?.signedUrl}
                        />
                      </Grid>
                    )}
                  </Grid>
                );
              })}
            </>
          )}
        </Grid>
        <Grid container sx={{ m: '1em 0em', padding: '1rem', justifyContent: 'center' }}>
          <Button
            disabled={loading}
            variant="contained"
            sx={{
              borderRadius: '10px',
              width: '7rem',
              height: '2.5rem',
              color: Colors.WHITE,
              textTransform: 'none',
              backgroundColor: Colors.DEEPBLUE,
              '&:hover': {
                backgroundColor: Colors.DEEPBLUE
              }
            }}
            onClick={handleClick}>
            {loading ? <CircularProgress size={25} color="inherit" /> : 'Submit'}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
export default SplitDoc;
