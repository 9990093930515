import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import theme from '../theme';
import { ThemeProvider } from '@mui/material/styles';
import { CircularProgress, Grid } from '@mui/material';
import {
  DashboardOutlined,
  InsertDriveFileOutlined,
  Attachment,
  CorporateFare
} from '@mui/icons-material';

import { getSelectedDocs, createCsv } from '../services/services';
import { approvalChildTabs, dashboardTabs } from '../services/common';
import { get_queue, get_tabs } from '../redux/actions/action';
import Layout from '../components/layout';
import HeaderSection from '../components/headerSection';
import DataTable from '../components/dataTable';
import Dashboard from './dashboard';
import { useToast } from '../toast/toastContext';
import Attachments from './attachments';
import UploadAttachments from './uploadAttachments';
import { searchAttachmentById, searchbyChequeNumber } from '../services/services';
import ErpDocuments from './erpDocuments';

export default function ClientHomePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const erpType = useSelector((state) => state?.userInfo?.userInfo?.user?.erpType);
  const activeIntegration = useSelector((state) => state?.userInfo?.userInfo?.activeIntegration);
  let clientParentTabs = [
    {
      name: 'Dashboard',
      icon: <DashboardOutlined />
    },
    {
      name: 'Documents',
      icon: <InsertDriveFileOutlined />
    },
    { name: 'Attachments', icon: <Attachment /> }
  ];
  if (erpType === 'Foundation' && activeIntegration !== '') {
    clientParentTabs = [...clientParentTabs, { name: 'Integrations', icon: <CorporateFare /> }];
  }
  const globalActiveTab = useSelector((state) => state?.tabs?.tabs);
  const parentActiveTab = globalActiveTab?.activeTab;
  const integrationTabs = [{ name: 'Search Integration' }];
  const [integrationChildTab, setIntegrationChildTab] = useState('Search Integration');
  const [activeTab, setActiveTab] = useState(parentActiveTab || clientParentTabs[0]?.name);
  const [childActiveTab, setChildActiveTab] = useState(
    globalActiveTab?.documentChildActiveTab || approvalChildTabs[0]?.name
  );
  const [dashboardChildTabs, setDashboardChildTabs] = useState(dashboardTabs[0]?.name);
  const [filteredData, setFilteredData] = useState([]);
  const [recordExist, setRecordExist] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [createCsvLoading, setCreateCsvLoading] = useState(false);
  const [searchProducts, setSearchProducts] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dataAccordingTabs, setDataAccordingTabs] = useState([]);
  const pendingQueues = useSelector((state) => state?.queue?.queue[0]);
  const [isAttachmentSearched, setIsAttachmentSearched] = useState(false);
  const [searchedResult, setSearchedResult] = useState([]);
  const [documentCount, setDocumentCount] = useState({});
  const isSearched = localStorage?.getItem('instrumentId');
  const [uploadAttachment, setUploadAttachment] = useState(
    isSearched || isSearched !== '' ? false : true
  );
  const [instrumentId, setInstumentId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchAttachments, setSearchAttachments] = useState('');
  const [isChequeLoading, setIsChequeLoading] = useState(false);
  const [searchedCheques, setSearchedCheques] = useState([]);
  const [chequeNum, setChequeNum] = useState('');
  const [isChequeSearched, setIsChequeSearched] = useState(false);
  const [selectedRowsHasPO, setSelectedRowsHasPO] = useState(true);
  const { showToast } = useToast();
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const callSource = userRole === 'Vendor' ? 'api' : 'app';

  const Tabs = {
    activeTab: activeTab,
    documentChildActiveTab: childActiveTab
  };
  useEffect(() => {
    dispatch(get_tabs(Tabs));
  }, [activeTab, childActiveTab]);
  useEffect(() => {
    setActiveTab(parentActiveTab || clientParentTabs[0]?.name);
    setChildActiveTab(globalActiveTab?.documentChildActiveTab || approvalChildTabs[0]?.name);
  }, [parentActiveTab, globalActiveTab?.documentChildActiveTab]);
  const allQueuesData = async () => {
    setLoading(true);
    let result = await getSelectedDocs(callSource);
    if (result?.response?.status === 401) {
      localStorage.clear();
      navigate('/');
    }
    dispatch(get_queue(result?.data?.payload?.data));
    setLoading(false);
  };

  useEffect(() => {
    allQueuesData();
  }, []);
  const intervalCall = async () => {
    let result;
    result = await getSelectedDocs(callSource);
    if (result?.response?.status === 401) {
      localStorage.clear();
      navigate('/');
    }
    dispatch(get_queue(result?.data?.payload?.data));
  };
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const interval = setInterval(() => {
        intervalCall();
      }, 30000);
      return () => clearInterval(interval);
    }
  }, []);

  const handleCreateCSV = async (e) => {
    e.preventDefault();
    setCreateCsvLoading(true);
    const params = {
      documentIds: checkedIds
    };
    const response = await createCsv(params);
    if (response?.status === 200) {
      showToast(response?.data?.metadata?.message, 'success');
      setCheckedIds('');
      allQueuesData();
    } else {
      showToast(response?.data?.metadata?.message, 'error');
    }
    setCreateCsvLoading(false);
  };
  const handleClear = () => {
    intervalCall();
    setRecordExist(false);
    setFilteredData([]);
    setSearchProducts('');
  };
  const handleClearAttachmentSearch = () => {
    setIsAttachmentSearched(false);
    setUploadAttachment(true);
    setSearchAttachments('');
    setSearchedResult([]);
    localStorage?.setItem('instrumentId', '');
  };
  const handleSearchAttachment = async (id) => {
    setIsLoading(true);
    const result = await searchAttachmentById(id || searchAttachments);
    setSearchedResult([]);
    if (result?.status === 200) {
      setUploadAttachment(false);
      if (!isEmpty(result?.data?.payload?.data)) {
        setSearchedResult(result?.data?.payload?.data);
        setInstumentId(result?.data?.payload?.data?.attachment?.referenceNo);
        localStorage?.setItem(
          'instrumentId',
          id || result?.data?.payload?.data?.attachment?.referenceNo
        );
      } else {
        setSearchedResult([]);
        setIsAttachmentSearched(true);
      }
    } else {
      showToast(
        result?.data?.metadata?.message || result?.response?.data?.metadata?.message,
        'error'
      );
    }
    setIsLoading(false);
  };
  const handleClearErpSearch = () => {
    setIsChequeSearched(false);
    setSearchedCheques([]);
    setChequeNum('');
    localStorage?.setItem('chequeNo', '');
  };
  const handleSearchFromErp = async (key) => {
    localStorage.setItem('erpType', erpType);
    setIsChequeLoading(true);
    const result = await searchbyChequeNumber(key || chequeNum);
    setSearchedCheques([]);
    if (result?.status === 200) {
      if (!isEmpty(result?.data?.payload?.data)) {
        setSearchedCheques(result?.data?.payload?.data);
        localStorage?.setItem('chequeNo', key || chequeNum);
      } else {
        setSearchedCheques([]);
        setIsChequeSearched(true);
      }
    } else {
      showToast(
        result?.data?.metadata?.message || result?.response?.data?.metadata?.message,
        'error'
      );
    }
    setIsChequeLoading(false);
  };
  return (
    <ThemeProvider theme={theme}>
      <Grid container sx={{ height: '100vh', backgroundColor: 'primary.light' }}>
        <Layout
          activeTab={parentActiveTab}
          setActiveTab={setActiveTab}
          currentTabs={clientParentTabs}
          path="clientHome"
          filteredData={filteredData}
          recordExist={recordExist}
          handleClear={handleClear}
          handleClearAttachmentSearch={handleClearAttachmentSearch}
          isAttachmentSearched={isAttachmentSearched}
          handleClearErpSearch={handleClearErpSearch}
        />
        <Grid container sx={{ paddingX: { lg: '3rem' } }}>
          <Grid
            container
            sx={{
              height: '81vh'
            }}>
            <HeaderSection
              currentTabs={
                parentActiveTab === 'Dashboard'
                  ? dashboardTabs
                  : parentActiveTab === 'Integrations'
                  ? integrationTabs
                  : approvalChildTabs
              }
              setActiveTab={
                parentActiveTab === 'Dashboard'
                  ? setDashboardChildTabs
                  : parentActiveTab === 'Integrations'
                  ? setIntegrationChildTab
                  : setChildActiveTab
              }
              activeTab={
                parentActiveTab === 'Dashboard'
                  ? dashboardChildTabs
                  : parentActiveTab === 'Integrations'
                  ? integrationChildTab
                  : childActiveTab
              }
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setLoading={setLoading}
              loading={loading}
              checkedIds={checkedIds}
              setCheckedIds={setCheckedIds}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              recordExist={recordExist}
              setRecordExist={setRecordExist}
              intervalCall={intervalCall}
              tab={
                parentActiveTab === 'Dashboard'
                  ? 'Dashboard'
                  : parentActiveTab === 'Attachments'
                  ? 'attachments'
                  : parentActiveTab === 'Integrations'
                  ? 'integration'
                  : 'documents'
              }
              createCsvLoading={createCsvLoading}
              currentUser="Client"
              handleCreateCSV={handleCreateCSV}
              searchProducts={searchProducts}
              setSearchProducts={setSearchProducts}
              dataAccordingTabs={dataAccordingTabs}
              searchAttachments={searchAttachments}
              setSearchAttachments={setSearchAttachments}
              isAttachmentSearched={isAttachmentSearched}
              handleClearAttachmentSearch={handleClearAttachmentSearch}
              searchedResult={searchedResult}
              uploadAttachment={uploadAttachment}
              setUploadAttachment={setUploadAttachment}
              handleSearchAttachment={handleSearchAttachment}
              setChequeNum={setChequeNum}
              handleSearchFromErp={handleSearchFromErp}
              chequeNum={chequeNum}
              isChequeSearched={isChequeSearched}
              searchedCheques={searchedCheques}
              setSearchedCheques={setSearchedCheques}
              handleClearErpSearch={handleClearErpSearch}
              integrationChildTab={integrationChildTab}
              selectedRowsHasPO={selectedRowsHasPO}
              documentCount={documentCount}
            />
            {loading ? (
              <Grid
                container
                sx={{
                  height: '75vh',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <CircularProgress size={150} color="primary" />
              </Grid>
            ) : parentActiveTab === 'Dashboard' ? (
              <Dashboard documentCount={documentCount} setDocumentCount={setDocumentCount} />
            ) : parentActiveTab === 'Attachments' && !uploadAttachment ? (
              <Attachments
                searchedResult={searchedResult}
                isAttachmentSearched={isAttachmentSearched}
                isLoading={isLoading}
                handleSearchAttachment={handleSearchAttachment}
                setSearchAttachments={setSearchAttachments}
                instrumentId={instrumentId}
                setInstumentId={setInstumentId}
              />
            ) : parentActiveTab === 'Attachments' && uploadAttachment ? (
              <UploadAttachments />
            ) : parentActiveTab === 'Integrations' ? (
              <ErpDocuments
                isLoading={isChequeLoading}
                handleSearchFromErp={handleSearchFromErp}
                setChequeNum={setChequeNum}
                isChequeSearched={isChequeSearched}
                searchedResult={searchedCheques}
              />
            ) : (
              <DataTable
                resdata={pendingQueues}
                setCheckedIds={setCheckedIds}
                checkedIds={checkedIds}
                filterData={filteredData}
                toShow="ApiSearch"
                recordExist={recordExist}
                currentUser="Client"
                isIntegrated={activeIntegration}
                childActiveTab={childActiveTab}
                setDataAccordingTabs={setDataAccordingTabs}
                setSelectedRowsHasPO={setSelectedRowsHasPO}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
