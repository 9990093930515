import React, { useState } from 'react';
import { useToast } from '../toast/toastContext';

import { Grid, CircularProgress, Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { Colors } from '../config/default';
import FileList from '../components/fileList';
import { isEmailValid } from '../services/validationService';
import { addManualUser } from '../services/services';
import { manualUserComponent } from '../constants/appConstants';

export default function AdminManualUsers({ data, setOpen, getManualUser, username }) {
  const { ADD_USER, AMOUNT_CHECK, UPLOAD } = manualUserComponent;
  const { showToast } = useToast();
  const [email, setEmail] = useState(data ? data : '');
  const [amount, setAmount] = useState('');
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(username ? username : '');

  const handleFileChange = (event) => {
    const file = event.target.files;
    const newFiles = Array.from(file);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const removeFile = (fileName) => {
    const filteredFile = files.filter((file) => file.name !== fileName);
    setFiles(filteredFile);
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
  });

  const isDisabled =
    email === '' ||
    amount === '' ||
    files.length === 0 ||
    !isEmailValid(email) ||
    name === '' ||
    parseFloat(amount) < 0.5;

  const handleClick = async () => {
    setLoading(true);
    const result = await addManualUser(files, email, amount, name);
    if (result?.status === 200) {
      showToast(result?.data?.metadata?.message, 'success');
      setOpen(false);
      getManualUser();
      setEmail('');
      setAmount('');
      setName('');
      setFiles([]);
    } else {
      showToast(
        result?.data?.metadata?.message || result?.response?.data?.metadata?.message,
        'error'
      );
    }
    setLoading(false);
  };

  return (
    <Grid sx={{ padding: '1em' }}>
      <Grid sx={{ textAlign: 'center', fontSize: '1.25em', color: Colors.WHITE }}>{ADD_USER}</Grid>
      <Grid xs={12} sx={{ mt: '1.5em' }}>
        <input
          readOnly={username ? true : false}
          style={{
            width: '96%',
            outline: 'none',
            border: `1px solid ${Colors.TEXTGREY}`,
            backgroundColor: 'transparent',
            padding: '1em 2%',
            borderRadius: '10px',
            color: Colors.WHITE
          }}
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Username"
          type="text"
        />
      </Grid>
      <Grid xs={12} sx={{ mt: '1em' }}>
        <input
          readOnly={data ? true : false}
          style={{
            width: '96%',
            outline: 'none',
            border: `1px solid ${Colors.TEXTGREY}`,
            backgroundColor: 'transparent',
            padding: '1em 2%',
            borderRadius: '10px',
            color: Colors.WHITE
          }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          type="text"
        />
      </Grid>
      <Grid xs={12} sx={{ mt: '1em' }}>
        <input
          style={{
            width: '96%',
            outline: 'none',
            border: `1px solid ${Colors.TEXTGREY}`,
            backgroundColor: 'transparent',
            padding: '1em 2%',
            borderRadius: '10px',
            color: Colors.WHITE
          }}
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Amount In Dollars"
          type="number"
        />
      </Grid>
      {parseFloat(amount) < 0.5 ? (
        <Box sx={{ color: Colors.RED, margin: '.3rem 0rem .9rem .2rem', fontSize: '13px' }}>
          {AMOUNT_CHECK}
        </Box>
      ) : (
        <Box sx={{ margin: '.3rem 0rem 1rem .2rem', fontSize: '14px', height: '13px' }}></Box>
      )}
      <Grid container>
        <Button
          variant="outlined"
          sx={{
            borderRadius: '10px',
            borderColor: Colors.WHITE,
            fontSize: '0.75em',
            color: Colors.WHITE,
            textTransform: 'none',
            '&:hover': {
              backgroundColor: 'transparent',
              borderColor: Colors.WHITE
            }
          }}
          component="label"
          startIcon={<CloudUploadIcon />}>
          {UPLOAD}
          <VisuallyHiddenInput onChange={handleFileChange} multiple type="file" />
        </Button>
        <Grid item xs={12} sx={{ marginTop: '.5rem', color: Colors.TEXTGREY }}>
          <FileList files={files} removeFile={removeFile} setFiles={setFiles} loading={loading} />
        </Grid>
      </Grid>
      <Grid container sx={{ justifyContent: 'center', mt: '2.5em' }}>
        <Button
          variant="outlined"
          disabled={isDisabled || loading}
          onClick={handleClick}
          sx={{
            width: '5rem',
            height: '2.2rem',
            borderRadius: '10px',
            color: Colors.WHITE,
            '&:hover': {
              backgroundColor: 'transparent',
              borderColor: 'none'
            }
          }}>
          {loading ? <CircularProgress size={22} color="inherit" /> : data ? 'Send' : 'Invite'}
        </Button>
      </Grid>
    </Grid>
  );
}
