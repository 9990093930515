import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Resizable } from 're-resizable';

import { ArrowRightAlt, DeleteOutline, GetAppOutlined, HistorySharp } from '@mui/icons-material';
import {
  Drawer,
  Button,
  Grid,
  CircularProgress,
  FormControlLabel,
  Switch,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

import LineItems from './lineItems';
import { canvasDetails, lineItemsText } from '../constants/appConstants';
import { Colors } from '../config/default';
import { compareObjects } from '../services/validationService';
import { getDocumentDetails } from '../services/services';
import { useToast } from '../toast/toastContext';
import { isEmpty } from 'lodash';
import ButtonDropdown from './buttonDropdown';
import { consolidateItems } from '../services/common';
import { HtmlTooltip } from '../services/common';

const StyledSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: Colors.WHITE
  },
  '& .MuiSwitch-track': {
    backgroundColor: Colors.RED,
    transparency: 1
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: Colors.TOURQUISE
  }
}));
export default function BottomDrawer({
  isDrawerOpen,
  setIsDrawerOpen,
  itemsData,
  handleClick,
  setRef,
  setLineItemsData,
  isLineItemsMatched,
  isPoMatched,
  endIcon,
  disabled,
  onClick,
  annotationType,
  data,
  costType,
  jobId,
  costId,
  phase,
  glAccount,
  obj,
  setObj,
  handleBoxClick,
  fetchingPO,
  lineItemInitialObj,
  openPrompt,
  setOpenPrompt,
  constructionCompany,
  valueChanged,
  fetchPo,
  lineItem,
  id,
  callSource,
  setLineItem,
  totalItems,
  setTolalItems,
  headers,
  handleFieldChange,
  imageDimensions,
  setClickedBoxIndex,
  onDtClick,
  refId,
  disableDt,
  isInvoiceNumberMatched,
  serviceCode,
  equipmentNo,
  serviceAndEquipmentPermission,
  setIsLineItemsMatched,
  matchingCallsrc,
  combineItems,
  setCombineItems,
  handleChange,
  disableConsolidate
}) {
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const allowConsolidateItems = userDetails?.company?.consolidateLineItems;
  const { showToast } = useToast();
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const [resetting, setResetting] = useState(false);

  const ResetLineItems = async () => {
    setResetting(true);
    setCombineItems(allowConsolidateItems);
    const dataFound = await getDocumentDetails(id, callSource);
    if (dataFound?.status === 200) {
      const data = dataFound?.data?.payload?.data?.document;
      const docType = data?.documentType;
      const combineItemsPermission =
        allowConsolidateItems &&
        (docType === 'Invoice' || docType === 'Receipt' || docType === 'G702');
      const consolidatedItems = consolidateItems(
        data?.lineItems,
        data?.standardizeContent?.total?.replace(/,/g, ''),
        headers,
        costId,
        jobId,
        glAccount,
        phase,
        costType,
        serviceCode,
        equipmentNo
      );
      setLineItem(combineItemsPermission ? [consolidatedItems] : data?.lineItems);
      setObj(lineItemInitialObj);
      setIsLineItemsMatched([]);
      showToast('Line items reset successfully', 'success');
    } else {
      showToast('Something went wrong ', 'error');
    }
    setResetting(false);
  };
  const deleteLineItems = async () => {
    setLineItem([]);
    Object.keys(obj)?.forEach((key) => {
      obj[key] = [];
    });
    setIsLineItemsMatched([]);
    showToast('Line items deleted successfully', 'success');
  };
  useEffect(() => {
    if (lineItemInitialObj[headers[0]]) {
      setTolalItems(new Array(lineItemInitialObj?.Amount?.length)?.fill(0));
    } else {
      setTolalItems([]);
    }
  }, [lineItem]);
  const resultArray = compareObjects(lineItemInitialObj, obj);
  function differentLineItems(obj) {
    if (obj === false) {
      return true;
    }
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === false) {
        return true;
      }
    }
    return false;
  }
  const isDifferentLineItems = differentLineItems(resultArray);

  useEffect(() => {
    if (costType) {
      if (combineItems || (valueChanged && !fetchingPO && resultArray?.userCostType)) {
        const newObj = { ...obj };
        newObj?.userCostType?.fill(costType);
        setObj(newObj);
      }
    }
  }, [costType]);
  useEffect(() => {
    if (costId) {
      if (combineItems || (valueChanged && !fetchingPO && resultArray?.userCostId)) {
        const newObj = { ...obj };
        newObj?.userCostId?.fill(costId);
        setObj(newObj);
      }
    }
  }, [costId]);
  useEffect(() => {
    if (jobId) {
      if (combineItems || (valueChanged && !fetchingPO && resultArray?.userJobId)) {
        const newObj = { ...obj };
        newObj?.userJobId?.fill(jobId);
        setObj(newObj);
      }
    }
  }, [jobId]);
  useEffect(() => {
    if (glAccount) {
      if (combineItems || (valueChanged && !fetchingPO && resultArray?.GlAccount)) {
        const newObj = { ...obj };
        newObj?.GlAccount?.fill(glAccount);
        setObj(newObj);
      }
    }
  }, [glAccount]);
  useEffect(() => {
    if (phase) {
      if (combineItems || (valueChanged && !fetchingPO && resultArray?.userPhases)) {
        const newObj = { ...obj };
        newObj?.userPhases?.fill(phase);
        setObj(newObj);
      }
    }
  }, [phase]);
  useEffect(() => {
    if (serviceCode) {
      if (combineItems || (valueChanged && !fetchingPO && resultArray?.serviceCode)) {
        const newObj = { ...obj };
        newObj?.serviceCode?.fill(serviceCode);
        setObj(newObj);
      }
    }
  }, [serviceCode]);
  useEffect(() => {
    if (equipmentNo) {
      if (combineItems || (valueChanged && !fetchingPO && resultArray?.equipmentNo)) {
        const newObj = { ...obj };
        newObj?.equipmentNo?.fill(equipmentNo);
        setObj(newObj);
      }
    }
  }, [equipmentNo]);

  const { SHOW_LINEITEMS } = lineItemsText;
  const { FETCH_PO, FETCH_PO_DESCRIPTION, RESET, FETCH_SC_DESCRIPTION, RESET_DESCRIPTION } =
    canvasDetails;

  const handleOpen = () => setOpenPrompt(true);
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const POPermission =
    userDetails?.user?.poMatchPermission &&
    annotationType !== 'Estimate' &&
    annotationType !== 'DeliveryTicket' &&
    annotationType !== 'Po';
  const list = (anchor) => (
    <Resizable
      defaultSize={{
        height: '305px',
        width: '100%'
      }}
      minHeight="150px"
      maxHeight="80vh"
      enable={{ top: true }}
      style={{
        overflow: 'hidden',
        backgroundColor: Colors.NAV_BLACK
      }}>
      <LineItems
        setDrawerOpen={setIsDrawerOpen}
        isPoMatched={isPoMatched}
        itemsData={itemsData}
        handleClick={handleClick}
        setRef={setRef}
        setLineItemsData={setLineItemsData}
        isLineItemsMatched={isLineItemsMatched}
        obj={obj}
        setObj={setObj}
        annotationType={annotationType}
        data={data}
        constructionCompany={constructionCompany}
        lineItem={lineItem}
        totalItems={totalItems}
        setTolalItems={setTolalItems}
        headers={headers}
        handleFieldChange={handleFieldChange}
        handleBoxClick={handleBoxClick}
        imageDimensions={imageDimensions}
        setClickedBoxIndex={setClickedBoxIndex}
        isInvoiceNumberMatched={isInvoiceNumberMatched}
        serviceAndEquipmentPermission={serviceAndEquipmentPermission}
        matchingCallsrc={matchingCallsrc}
      />
      <Grid
        item
        xs={12}
        sx={{
          height: '19.5%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginBottom: '1rem',
          backgroundColor: Colors.NAV_BLACK
        }}>
        {(annotationType === 'Invoice' ||
          annotationType === 'G702' ||
          annotationType === 'Receipt') && (
          <FormControlLabel
            disabled={disableConsolidate}
            sx={{
              color: Colors.WHITE,
              '& .MuiFormControlLabel-label.Mui-disabled': {
                color: Colors.WHITE
              },
              fontSize: { xs: '10px', lg: '15px' }
            }}
            control={
              <StyledSwitch
                size="small"
                checked={disableConsolidate ? true : combineItems}
                onChange={(event) => handleChange(event)}
              />
            }
            label="Consolidate Line items"
          />
        )}

        <Button
          disabled={isEmpty(lineItem)}
          sx={{
            textTransform: 'unset !important',
            borderRadius: '5px',
            color: Colors.RED,
            fontSize: { xs: '10px', lg: '15px' },
            width: '8rem',
            height: '2.5rem',
            '&:hover': {
              background: 'none'
            },
            '&:disabled': {
              color: 'gray',
              cursor: 'not-allowed'
            }
          }}
          onClick={deleteLineItems}>
          Delete All
          <DeleteOutline sx={{ width: { xs: '15px', lg: '20px' }, ml: 1 }} />
        </Button>
        <HtmlTooltip
          title={
            <Typography color="inherit" sx={{ fontSize: '1em' }}>
              {RESET_DESCRIPTION}
            </Typography>
          }>
          <Button
            disabled={!isDifferentLineItems || resetting}
            sx={{
              textTransform: 'unset !important',
              borderRadius: '5px',
              color: Colors.RED,
              fontSize: { xs: '10px', lg: '15px' },
              width: '6.5em',
              height: '2.5rem',
              '&:hover': {
                background: 'none'
              },
              '&:disabled': {
                color: 'gray',
                cursor: 'not-allowed'
              }
            }}
            onClick={ResetLineItems}>
            {resetting ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <>
                {RESET}
                <HistorySharp sx={{ width: { xs: '13px', lg: '20px' }, ml: 1 }} />
              </>
            )}
          </Button>
        </HtmlTooltip>
        {annotationType !== 'Estimate' &&
          annotationType !== 'DeliveryTicket' &&
          annotationType !== 'Po' && (
            <>
              <ButtonDropdown
                text="Fetch"
                width="8rem"
                showPrompt={true}
                disable={!userDetails?.user?.poMatchPermission}
                icon={<GetAppOutlined sx={{ width: { xs: '13px', lg: '20px' } }} />}
                color={Colors.WHITE}
                openPrompt={openPrompt}
                setOpenPrompt={setOpenPrompt}
                promptLoading={fetchingPO}
                helperText="Fetch Line Items"
                options={[
                  {
                    name: 'Purchase Order',
                    disable: disabled,
                    action: handleOpen,
                    promptTitle: FETCH_PO,
                    promptDescription: FETCH_PO_DESCRIPTION,
                    promptFunction: () => fetchPo('Po'),
                    showButton: POPermission && annotationType !== 'G702'
                  },
                  {
                    name: 'Subcontract',
                    disable: disabled,
                    action: handleOpen,
                    promptTitle: 'Fetch Subcontract',
                    promptDescription: FETCH_SC_DESCRIPTION,
                    promptFunction: () => fetchPo('subContract'),
                    showButton: POPermission
                  }
                ]}
              />
              <ButtonDropdown
                text="Match"
                width="8rem"
                icon={endIcon}
                color={Colors.WHITE}
                showPrompt={false}
                helperText="Match Line Items"
                disable={
                  !userDetails?.user?.poMatchPermission && !userDetails?.user?.deliveryTicketUpload
                }
                options={[
                  {
                    name: 'Purchase Order',
                    disable: disabled,
                    action: () => onClick('Po'),
                    showButton: POPermission && annotationType !== 'G702'
                  },
                  {
                    name: 'Delivery Ticket',
                    disable: disableDt || isEmpty(refId),
                    action: () => onDtClick('DeliveryTicket'),
                    showButton:
                      (annotationType === 'Invoice' || annotationType === 'G702') &&
                      userDetails?.user?.deliveryTicketUpload
                  },
                  {
                    name: 'Subcontract',
                    disable: disabled,
                    action: () => onClick('subContract'),
                    showButton: POPermission
                  }
                ]}
              />
            </>
          )}
      </Grid>
    </Resizable>
  );

  return (
    <div>
      {['bottom']?.map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            style={{
              fontSize: '13px',
              borderRadius: '5px',
              background: 'transparent',
              color: '#ffffff'
            }}
            endIcon={<ArrowRightAlt sx={{ fontSize: '15px', color: Colors.TOURQUISE }} />}
            onClick={() => setIsDrawerOpen(true)}>
            {SHOW_LINEITEMS}
          </Button>
          <Drawer
            style={{ position: 'initial' }}
            hideBackdrop
            disableEnforceFocus
            anchor={anchor}
            open={isDrawerOpen}
            onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
